import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import {


    resetForgetPassword, forgetPasswordRequest
} from "../../../store/actions"
// import NidaFormart from '../../../utils/formartNida'

import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import theme from '../../../utils/theme';
import { Avatar, Card } from '@mui/joy';

import { useNavigate } from 'react-router-dom';


import image from '../../../constant/image';
import { NAVIGATE_TO_LOGINPAGE } from '../../../route/types';
import { toast } from 'react-toastify';
import { STATUS } from '../../../constant';
import { LoadingView } from '../../../components';
import { IMaskInput } from 'react-imask';
import { NumericFormat, NumericFormatProps, PatternFormat, PatternFormatProps } from 'react-number-format';
import { NidaFormat } from '../../../utils';

interface FormElements extends HTMLFormControlsCollection {
    nida: HTMLInputElement;
    // usermail: HTMLInputElement;
    mobile: HTMLInputElement;
    // persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const ForgetPasswordPage = ({
    status,
    errorMessage
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [nida, setNida] = useState('');

    useEffect(() => {
        if (status === STATUS.SUCCESS) {
            // reset the guest app state
            toast.success("Password reset successful. Confirmation sent to registered mobile number.")
            setTimeout(() => {
                navigate(NAVIGATE_TO_LOGINPAGE)
            }, 1000)
            dispatch(resetForgetPassword());
        }
        else if (status == STATUS.ERROR) {
            // reset the guest app state
            toast.error(errorMessage)
            dispatch(resetForgetPassword());
        }
        //console.log(status);
    })


    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            nida: nida,
            mobile: formElements.mobile.value,
        };
        // console.log("Forget Password data: ", data)
        // toast(`Nida: ${nida}`)
        if (data.nida != "" && data.mobile != "") {
            dispatch(forgetPasswordRequest(data.nida, data.mobile))

        } else {
            toast.error("Please fill out all fields");
        }
    }

    // function to formart nida number
    // const formatNidaValue = (value) => {
    //     // Remove non-numeric characters from the input value
    //     const numericValue = value.replace(/\D/g, '');

    //     // Add hyphens at specific positions
    //     // const formattedValue = `${numericValue.slice(0, 8)}-${numericValue.slice(8, 13)}-${numericValue.slice(13,18)}-${numericValue.slice(18,20)}`;
    //     const formattedValue = numericValue.replace(/(\d{8})(\d{5})(\d{5})(\d{2})/, "$1-$2-$3-$4")

    //     // Update the state with the formatted value
    //     // setNida(formattedValue);
    //     return formattedValue
    //   };

    const checkLoading = () => {
        if (status === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <CssVarsProvider defaultMode="light" disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '600px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />

            {/* loading  */}
            <LoadingView loading={checkLoading()} />

            <Box
                sx={(theme) => ({
                    //   width: '90%',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',

                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        `url(${image.Images.backgroung})`,
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            `url(${image.Images.backgroung2})`,
                    },
                })}
            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        // width:
                        //   'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 4,
                            gap: 1,
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            // flexDirection: 'column',
                        }}
                    >
                        <Avatar
                            src={image.Images.logoWhite}
                            size='sm'
                            sx={{
                                maxWidth: 80, maxHeight: 80, backgroundColor: 'primary.500', padding: '5px',
                            }}
                        />
                        <Typography level='title-lg'>SAVA</Typography>
                    </Box>
                    <Card
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',

                            backgroundColor: 'background.body',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography level="h3">Forget Password</Typography>
                            </Stack>
                        </Stack>

                        {/* ambassadorclient switch */}
                        <Typography level="body-sm" sx={{ textAlign: 'center' }}>
                            Please insert correct information to proceed with pin reset
                        </Typography>

                        <Stack component='form' onSubmit={handleSubmit} gap={4} sx={{ mt: 2 }} noValidate>
                            <FormControl required>
                                <FormLabel>Nida</FormLabel>
                                <Input
                                    // type="number" 
                                    name="nida"
                                    value={nida}
                                    onChange={(event) => setNida(event.target.value)}
                                    placeholder='Enter nida number'
                                    slotProps={{
                                        input: { component: NidaFormat}
                                    }} />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Mobile Number</FormLabel>
                                <Input type="tel" name="mobile" placeholder='ex 0689010101' />
                            </FormControl>
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <Button type="submit" fullWidth>
                                    Submit
                                </Button>
                            </Stack>
                        </Stack>
                    </Card>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center" sx={{ color: 'whitesmoke' }}>
                            © Sava Tanzania {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>

        </CssVarsProvider>
    );
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}


const mapStateToProps = ({ guest }) => {
    const {
        forgetPasswordStatus: status,
        forgetPasswordErrorMessage: errorMessage
    } = guest;

    return {
        status,
        errorMessage
    }
}

export default connect(mapStateToProps, {})(ForgetPasswordPage)