import { call, put, takeLatest } from 'redux-saga/effects';
import { ACTION_ACCOUNT_SETUP, CLIENT } from '../../constant';
import { doFetchTermsAndCondition, doForgetPassword, doGuestLogin } from '../../service/guest';
import errorMessage from '../../utils/client_error.json';
// import { saveData } from '../../utils/storage';


function* guestLoginTask(action) {
    try {
        yield put({ type: CLIENT.GUEST_LOGIN_LOADING });
        
        const { payload } = action; 

        const res = yield call( doGuestLogin, payload.username, payload.password, payload.fcm_token);

        if (res.status == 200) {

            //console.log("Please the data" , res.data);

            // if (res.data.token) {
            //     //save to internal db
            //     saveData("mobile", res.data.mobile);
            // }

            yield put({
                type: CLIENT.GUEST_LOGIN_SUCCESS,
            });

            yield put({
                type: ACTION_ACCOUNT_SETUP,
            });

            yield put({
                type: CLIENT.USER_LOGIN_SUCCESS,
                payload: res.data
            })
            
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.GUEST_LOGIN_FAILURE,
                payload: res.message //error message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.GUEST_LOGIN_FAILURE,
            payload: errMsg //'error occured' //error.message
        })
    }
} 

function* termsAndConditionTask(action) {
    try {
        yield put({ type: CLIENT.TERMS_AND_CONDITION_LOADING });

        const { payload } = action

        const res = yield call(doFetchTermsAndCondition, payload.language);

        if (res.status == 200) {
            yield put({
                type: CLIENT.TERMS_AND_CONDITION_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.TERMS_AND_CONDITION_FAILURE,
                payload: res.message //error message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.TERMS_AND_CONDITION_FAILURE,
            payload: errMsg
        })
    }
}

function* forgetPasswordTask(action) {
    try {
        yield put({ type: CLIENT.FORGET_PASSWORD_LOADING });

        const { payload } = action;

        const res = yield call(doForgetPassword, payload.nida, payload.mobile);

        if (res.status == 200) {
            yield put({
                type: CLIENT.FORGET_PASSWORD_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.FORGET_PASSWORD_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.FORGET_PASSWORD_FAILURE,
            payload: errMsg
        })
    }
}

function* guestSaga() {
    yield takeLatest(CLIENT.GUEST_LOGIN_REQUEST, guestLoginTask);
    yield takeLatest(CLIENT.TERMS_AND_CONDITION_REQUEST, termsAndConditionTask);
    yield takeLatest(CLIENT.FORGET_PASSWORD_REQUEST, forgetPasswordTask);
}

export default guestSaga;