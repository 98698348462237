import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, DialogContent, DialogTitle, FormControl, FormLabel, Input, List, ListItem, Modal, ModalClose, ModalDialog, Slider, Stack, Tooltip, Typography, checkboxClasses } from "@mui/joy";
import { toast, ToastContainer } from 'react-toastify';
import Divider from '@mui/joy/Divider';
import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import thousandSeparator from "../../../utils/thousandSeparator";
import { NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE, NAVIGATE_TO_LOANDESCRIPTIONPAGE, NAVIGATE_TO_LOANUNDERWRITINGPAGE, NAVIGATE_TO_REPAYMENTPLANPAGE } from "../../../route/types";
import { InfoRounded, RemoveRedEyeRounded } from "@mui/icons-material";

import { fetchSupportContent, fetchSupportContentReset, ambassadorValidateRequest, ambassadorValidateReset } from "../../../store/actions"
import { STATUS } from "../../../constant";

interface FormElements extends HTMLFormControlsCollection {
    ambassadorTill: HTMLInputElement;
    // persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const ClientLoanRequestPage = ({
    loginResult,
    supportDataResult,
    supportDataStatus,
    supportDataErrorMessage,

    requestStatus,
    requestResult,
    requestErrorMessage
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { payment_plans, maximum_limit, loan_categories, sava_min_amount, client_name, token } = loginResult;
    const [amount, setAmount] = useState(parseInt(maximum_limit) / 2);
    const [selectedPlan, setSelectedPlan] = useState(0)
    const [loanDuration, setLoanDuration] = useState(61);
    const [loanFee, setLoanFee] = useState(0);
    const [totalLoan, setTotalLoan] = useState(0);
    const [cycleCount, setCycleCount] = useState(0);
    const [FeeAndLoan, setFeeAndLoan] = useState(0);

    const [loanRate, setLoanRate] = useState(0);

    const [ambassadorModal, setAmbassadorModal] = useState(false);
    const [loadAmbassador, setLoadAmbassador] = useState(false);
    const [ambassadorData, setAmbassadorData] = useState({ name: '', tile: "", underwriting: false })

    const [repaymentModal, setRepaymentModal] = useState(false)

    useEffect(() => {
        if (supportDataStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_LOANDESCRIPTIONPAGE, {
                state: {
                    content: supportDataResult.content
                }

            });
            dispatch(fetchSupportContentReset());
        }
        else if (supportDataStatus === STATUS.ERROR) {
            toast.error("Failed to fetch loan descriptions")
            dispatch(fetchSupportContentReset());
        }

        if (requestStatus === STATUS.SUCCESS) {
            //dropDownAlertRef.alertWithType('success', 'success', 'Congratulation');
            setLoadAmbassador(false)
            // setAmbassadorModal(false)
            setAmbassadorData({
                name: requestResult.ambassador_name,
                tile: requestResult.ambassador_tile,
                underwriting: requestResult.underwriting_qns
                // underwriting: true
            })
            // navigate(NAVIGATE_TO_LOANUNDERWRITINGPAGE)
            dispatch(ambassadorValidateReset());
        } else if (requestStatus === STATUS.ERROR) {
            setLoadAmbassador(false)
            toast.error(requestErrorMessage)
            dispatch(ambassadorValidateReset());
        }
    }, [supportDataStatus, requestStatus]);

    // FUNCTION TO VERIFY AMBASSADOR AND NAVIGATE TO NEXT PAGE
    const handleSubmitTill = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            ambassadorTill: formElements.ambassadorTill.value,
            // password: formElements.password.value,
            // persistent: formElements.persistent.checked,
        };
        console.log(data)
        if (data.ambassadorTill !== "") {
            if (ambassadorData.name === "") {
                setLoadAmbassador(true)
                dispatch(ambassadorValidateRequest(token, data.ambassadorTill, amount))
            } else {
                setLoadAmbassador(false);
                setAmbassadorModal(false);
                if (ambassadorData.underwriting === true){
                    navigate(NAVIGATE_TO_LOANUNDERWRITINGPAGE, {
                        state: {
                            loanAmount: amount,
                            totalAmount: totalLoan,
                            duration: cycleCount + " " + loanDuration,
                            selectedPlan: selectedPlan,
                            ambassador_name: ambassadorData.name,
                            ambassador_tile: ambassadorData.tile,
                            // underwriting: ambassadorData.underwriting,
                            // underwriting: "true"
                        }
                    })
                } else {
                    navigate(NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE, {
                        state: {
                            loanAmount: amount,
                            totalAmount: totalLoan,
                            duration: cycleCount + " " + loanDuration,
                            selectedPlan: selectedPlan,
                            ambassador_name: ambassadorData.name,
                            ambassador_tile: ambassadorData.tile,
                        }
                    })
                }
            }

        } else {
            toast.error("Please fill out all fields");
        }
    };

    // FUNCTION TO OPEN AMBASSADOR MODAL
    const openModal = () => {
        if (selectedPlan && amount) {
            setAmbassadorModal(true);

        } else {
            alert("Please select payment plan!");
        }
    }

    // EFFECT TO SET LOAN DETAILS
    useEffect(() => {
        changeLoanFee(amount)
        let _selectedPlan = payment_plans.find(p => p.id === selectedPlan);
        if (_selectedPlan != null) {
            setLoanRate(parseInt(_selectedPlan.rate));
            setCycleCount(parseInt(_selectedPlan.cycle_count));
            setLoanDuration(_selectedPlan.duration);
            let total_loan_and_fee = amount + loanFee
            let _loanInterestAmount = parseInt(_selectedPlan.rate) * total_loan_and_fee * parseInt(_selectedPlan.cycle_count) / 100;
            setTotalLoan(total_loan_and_fee + _loanInterestAmount);
            setFeeAndLoan(total_loan_and_fee)
        }


    }, [amount, loanFee, selectedPlan])



    // function to change loan fee with reference of amount
    const changeLoanFee = (amt) => {
        for (var cat of loan_categories) {
            if (amt >= parseInt(cat.start_amount) && amt <= parseInt(cat.end_amount)) {
                setLoanFee(parseInt(cat.fee));
            }
        }
    }

    // function to store amount in state 
    const handleSliderChange = (event, newValue) => {
        setAmount(newValue);
        // You can perform any additional actions here with the new value
    };

    // function to direct to payment plan page
    const viewRepaymentPlan = () => {
        if (!selectedPlan) {
            alert("Please select payment plan");
            return;
        }
        setRepaymentModal(true)
    }

    // loan info decription data call
    const viewLoanDescription = () => {
        let category = "description";
        let language = "sw";
        dispatch(fetchSupportContent(token, language, category));
    }

    // PLAN LIST RENDERING
    const planList = () => {

        return (

            <List
                sx={{
                    '--ListItem-gap': '0.75rem',
                    width: { xs: '100%', md: '45%' },
                    gap: 0.5,
                    [`& .${checkboxClasses.root}`]: {
                        mr: 'auto',
                        flexGrow: 1,
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                    },

                }}>
                <Typography level="title-sm">Select Loan Duration</Typography>
                {payment_plans.map((plan) => {
                    const selectPlan = (event) => {
                        setSelectedPlan(event.target.value)

                    }
                    return (
                        <ListItem
                            variant="outlined"
                            sx={{
                                backgroundColor: selectedPlan === plan.id ? 'background.level3' : 'transparent',
                                borderRadius: 10,
                            }}>
                            <Checkbox
                                overlay
                                checked={selectedPlan === plan.id}
                                value={plan.id}
                                onChange={selectPlan}
                                label={
                                    <React.Fragment>
                                        <Typography level="body-sm" sx={{ color: 'text.primary' }}>{plan.name}</Typography>
                                        <Typography level="body-sm" sx={{ display: 'block' }}>{plan.cycle_count + " " + plan.duration}</Typography>
                                    </React.Fragment>
                                } />
                        </ListItem>)
                })}
            </List>
        )

    }

    // REPAYMENT PLAN MODAL 
    const repaymentModa = () => {
        const calculateDiscountDate = (duration) => {
            var date = new Date();
            date.setDate(date.getDate() + duration);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        }

        const renderPayPlan = () => {
            let _installment_amount = (totalLoan / cycleCount);
            let month_days = 30;
            let _view = [];

            _view.push(
                <>
                    <Box
                        key={0}
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}>
                        <Typography level="title-md">Pay Date</Typography>
                        <Typography level="title-md">Installment Amount</Typography>

                    </Box>
                    <Divider />
                </>


            );

            for (var count = 1; count <= cycleCount; count++) {
                _view.push(
                    <>
                        <Box
                            key={count}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pt: 1
                            }}>
                            <Typography level="body-md">{calculateDiscountDate(month_days * count)}</Typography>
                            <Typography level="body-md" textAlign='right'>{"Tsh. " + thousandSeparator(_installment_amount)}</Typography>

                        </Box>
                        <Divider />
                    </>

                )
            }
            return _view;
        }

        const loan_Details = [
            { title: "Request Amount", content: "Tsh. " + thousandSeparator(amount) },
            { title: "Application Fee", content: "Tsh. " + thousandSeparator(loanFee) },
            { title: "Rate", content: loanRate + "%" },
            { title: "Loan Duration", content: cycleCount + loanDuration },
            { title: "Total Loan Request", content: "Tsh. " + thousandSeparator(FeeAndLoan) },
            { title: "Total Payment Amount", content: "Tsh. " + thousandSeparator(totalLoan) }
        ]

        return (
            <Modal open={repaymentModal} onClose={() => setRepaymentModal(false)}>
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    size="md"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                        width: {xs: "100%", md: "45%"}
                    })}>
                    <ModalClose variant="outlined" />
                    <DialogTitle>Repayment Plans</DialogTitle>
                    <DialogContent>
                        <Typography level="title-md" textAlign={'center'}>Loan Details</Typography>
                        {loan_Details.map((details) => (
                            <>
                                <Box
                                    key={0}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        pt: 1
                                    }}>
                                    <Typography level="body-md">{details.title}</Typography>
                                    <Typography level="body-md" textAlign='right'><b>{details.content}</b></Typography>

                                </Box>
                                <Divider />
                            </>

                        ))}
                    </DialogContent>
                    <DialogContent>
                        <Typography level="title-md" textAlign={'center'}>Repayments</Typography>
                        {renderPayPlan()}
                    </DialogContent>

                </ModalDialog>
            </Modal>
        )

    }


    // function to check loading...
    const checkLoading = () => {
        if (supportDataStatus === STATUS.LOADING) {
            return true
        }
        else {
            return false
        }
    }

    // MAIN RETURN
    return (
        <Box>
            <PageTitle title={'Apply Loan'} />
            {/* <Typography variant="div"> */}
            {/* <ToastContainer /> */}

            <LoadingView loading={checkLoading()} />
            <Card variant="outlined" sx={{ backgroundColor: 'background.level1' }}>
                <CardContent>
                    {/* loan amount */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'

                    }}>
                        <Tooltip title='Loan info description'>
                            <Button variant="plain"
                                onClick={() => { viewLoanDescription() }}
                                sx={{ color: 'background.appcolor', position: 'absolute', top: 10, right: 20 }} >
                                <InfoRounded />
                            </Button>
                        </Tooltip>
                        <Typography level="title-md" textAlign={'center'}>
                            Choose Loan Amount
                        </Typography>
                        <Typography level="h1" textAlign={'center'}>
                            {thousandSeparator(amount)} Tsh
                        </Typography>
                        <Slider
                            aria-label="Small steps"
                            defaultValue={amount}
                            value={amount}
                            onChange={handleSliderChange}
                            step={10000}
                            min={parseInt(sava_min_amount)}
                            max={parseInt(maximum_limit)}
                            valueLabelDisplay="auto"
                            sx={{
                                width: { xs: '80%', md: '70%' },
                            }}
                        />
                    </Box>


                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 2
                    }}>
                        {/* loan duration */}
                        {planList()}

                        <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} />

                        {/* Loan Details */}
                        <Box
                            sx={{
                                width: { xs: '100%', md: '50%' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                // alignItems: 'center',
                                gap: 0.5
                            }}>
                            <Typography level="title-sm">Loan Details</Typography>
                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">Request Amount</Typography>
                                <Typography level="body-sm">{thousandSeparator(amount)} Tsh</Typography>
                            </Box>
                            <Divider />

                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">Application Fee</Typography>
                                <Typography level="body-sm">{thousandSeparator(loanFee)} Tsh</Typography>
                            </Box>
                            <Divider />

                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">Rate</Typography>
                                <Typography level="body-sm">{loanRate} %</Typography>
                            </Box>
                            <Divider />

                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">Total Loan</Typography>
                                <Typography level="body-sm">{thousandSeparator(FeeAndLoan)} Tsh</Typography>
                            </Box>
                            <Divider />

                            {/* <Link to={NAVIGATE_TO_REPAYMENTPLANPAGE}> */}
                            <Button
                                startDecorator={<RemoveRedEyeRounded />}
                                variant="outlined"
                                sx={{ alignSelf: 'center', marginTop: '10px', color: 'background.appcolor' }}
                                onClick={viewRepaymentPlan}>
                                View Repayment Plans
                            </Button>
                        </Box>
                    </Box>

                </CardContent>
                <CardActions>
                    <Button onClick={openModal}>NEXT</Button>
                </CardActions>
            </Card>

            {/* AMBASSODAR MODAL */}
            <Modal open={ambassadorModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" onClick={() => setAmbassadorModal(false)} />
                    <DialogTitle>Ambassador Confirmation</DialogTitle>
                    <DialogContent>Please enter ambassador till number to continue.</DialogContent>
                    <form
                        onSubmit={handleSubmitTill}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            <FormLabel>Till Number</FormLabel>
                            <Input
                                autoFocus
                                type="number"
                                required
                                name="ambassadorTill"
                            />
                            {ambassadorData.name !== "" && (
                                <>
                                    <Typography>Ambassador name: <b>{ambassadorData.name}</b></Typography>
                                    <Typography>Ambassador Tile: <b>{ambassadorData.tile}</b></Typography>
                                </>

                            )}

                        </FormControl>

                        <Button
                            startDecorator={loadAmbassador && <CircularProgress />}
                            type="submit"
                            fullWidth>
                            {loadAmbassador ? "LOADING..." : (ambassadorData.name !== "" ? "CONTINUE" : "CONFIRM AMBASSADOR")}
                        </Button>
                        {ambassadorData.name !== "" && <Button
                            variant="soft"
                            onClick={() => setAmbassadorData({
                                name: '',
                                tile: '',
                                underwriting: false
                            })}
                            fullWidth
                            sx={{ mt: 1 }}>
                            RESET
                        </Button>}
                    </form>
                </ModalDialog>
            </Modal>

            {/* repayment modal call */}
            {repaymentModa()}

        </Box>
    )
}

const mapStateToProps = ({ authClient, account, loan }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        requestSupportListResult: supportDataResult,
        requestSupportListStatus: supportDataStatus,
        requestSupportListErrorMessage: supportDataErrorMessage,
    } = account
    const {
        ambassadorValidateStatus: requestStatus,
        ambassadorValidateErrorMessage: requestErrorMessage,
        ambassadorResult: requestResult
    } = loan

    return {
        loginResult,
        supportDataResult,
        supportDataStatus,
        supportDataErrorMessage,

        requestStatus,
        requestResult,
        requestErrorMessage
    }
}
export default connect(mapStateToProps, {})(ClientLoanRequestPage)