import { fork } from "@redux-saga/core/effects";

import authClientSaga from "./auth/sagas";
import guestSaga from "./guest/sagas";
import loanSaga from "./loan/sagas";
import accountClientSaga from "./account/sagas";
import registrationSaga from "./registration/sagas";


export default function* root() {
    yield fork(authClientSaga);
    yield fork(guestSaga);
    yield fork(loanSaga);
    yield fork(accountClientSaga);
    yield fork(registrationSaga);
}