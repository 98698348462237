import { ACTIVE_LOAN_DETAIL_URL, AMBASSADOR_VALIDATE_URL, LOAN_APPROVAL_URL, LOAN_HISTORY_URL, PENDING_LOAN_DETAIL_URL, PUSH_PAYMENT_URL, UNDERWRINTING_URL } from "../../constant";
import { multipartRequest, resourceRequest } from '../calls';

function doAmbassadorValidateRequest(token, tile, amount) {
    const data = {
        tile,
        amount
    };

    return resourceRequest(token, AMBASSADOR_VALIDATE_URL, data);
}

function doSendUnderwriting(token, tile, amount, duration, pay_plan, source_of_income, monthly_income, loan_use,
    collateral, guarantor_name, guarantor_phone, guarantor_relationship) {

    const formData = new FormData();

    // Append non-file data
    formData.append("tile", tile);
    formData.append("amount", amount);
    formData.append("duration", duration);
    formData.append("pay_plan", pay_plan);
    formData.append("source_of_income", source_of_income);
    formData.append("monthly_income", monthly_income);
    formData.append("loan_use", loan_use);
    // formData.append("collateral", collateral);
    formData.append("guarantor_name", guarantor_name);
    formData.append("guarantor_phone", guarantor_phone);
    formData.append("guarantor_relation", guarantor_relationship);

    collateral.forEach((item, index) => {
        const picture = item.collateral_picture;
        const file = picture.file; // Assuming you have a file object here
    
        // Append collateral picture as file
        formData.append(`collateral[${index}][collateral_picture]`, file);
    
        // Append other collateral data
        formData.append(`collateral[${index}][collateral_name]`, item.collateral_name);
        formData.append(`collateral[${index}][collateral_value]`, item.collateral_value);
    
        // Append collateral location data
        const location = item.collateral_location;
        formData.append(`collateral[${index}][collateral_location][latitude]`, location.latitude);
        formData.append(`collateral[${index}][collateral_location][longitude]`, location.longitude);
    });

    return multipartRequest(token, UNDERWRINTING_URL, formData);
}

function doLoanRequest(token, tile, amount, duration, pay_plan) {
    const data = {
        tile,
        amount,
        duration,
        pay_plan
    }

    return resourceRequest(token, LOAN_APPROVAL_URL, data);
}

function doLoanHistoryRequest(token) {
    const data = {};

    return resourceRequest(token, LOAN_HISTORY_URL, data);
}

function doPendingLoanDetailRequest(token) {
    const data = {};
    return resourceRequest(token, PENDING_LOAN_DETAIL_URL, data);
}

function doActiveLoanDetailRequest(token) {
    const data = {}
    return resourceRequest(token, ACTIVE_LOAN_DETAIL_URL, data);
}

function doInitiatePushPaymentRequest(token, amount) {
    const data = {
        amount: amount
    }
    return resourceRequest(token, PUSH_PAYMENT_URL, data);
}

export {
    doSendUnderwriting,
    doAmbassadorValidateRequest,
    doLoanRequest,
    doLoanHistoryRequest,
    doPendingLoanDetailRequest,
    doActiveLoanDetailRequest,
    doInitiatePushPaymentRequest
}