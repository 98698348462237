import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, Card, CardContent, FormControl, FormLabel, Input, Select, Stack, Typography, Option, Checkbox, Link, AspectRatio, ButtonGroup, Divider, IconButton } from "@mui/joy";
import { toast, ToastContainer } from 'react-toastify';
import { ClientMain, LoadingView, MapContainer, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { IMaskInput } from 'react-imask';

import { clientDetailsRequest, loanApprovalRequest, loanApprovalReset, termsAndConditionRequest, resetTermsAndConditionRequest, underwritingRequest, underwritingReset, uploadProfilePhoto, uploadProfilePhotoReset, uploadSignatureRequest, uploadSignatureReset } from "../../../store/actions";
import { API_BASE, STATUS } from "../../../constant";
import { NAVIGATE_TO_HOMECLIENTPAGE, NAVIGATE_TO_TERMSANDCONDITIONPAGE } from "../../../route/types";
import { EditRounded, LocationOn, PictureAsPdf, PictureInPicture, PictureInPictureAltRounded } from "@mui/icons-material";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface FormElements extends HTMLFormControlsCollection {
    // incomeSrc: HTMLInputElement;
    // incomeAmount: HTMLInputElement;
    loanUse: HTMLInputElement;
    guarantorName: HTMLInputElement;
    guarantorNumber: HTMLInputElement;
    guaruntorRelation: HTMLInputElement;
    checkBox: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const LoanUnderWritingPage = ({
    loginResult,
    requestStatus,
    requestErrorMessage,
    requestResult,
    termsResult,
    termsStatus,
    termsErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,

    underWritingStatus,
    underWritingErrorMessage,
    underWritingResult,
}) => {

    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()

    const picRef = useRef(null)
    const signRef = useRef(null)


    const [profile, setProfile] = useState({
        uri: '',
        file: null,
    });
    const [signature, setSignature] = useState({
        uri: '',
        file: null,
    })

    const [extendScr, setExtendSrc] = useState(false)
    const [incomeSrc, setIncomeSrc] = useState("")
    const [loanUse, setLoanUse] = useState({
        data: "",
        extend: false
    })
    const [incomeAmount, setIncomeAmount] = useState("")

    const [collateralFields, setCollateralFields] = useState([{
        collateral_name: '',
        collateral_value: '',
        collateral_picture: {
            uri: '',
            file: null
        },
        collateral_location: {
            latitude: 0,
            longitude: 0
        }
    }])

    const { ambassador_tile, ambassador_name, loanAmount, totalAmount, selectedPlan, duration } = state

    // fetch client profile
    useEffect(() => {
        if (clientDetailsStatus === STATUS.SUCCESS) {
            // console.log("CLIENT DETAILS: ", clientDetailsResult)
            if (clientDetailsResult.message.profile_picture != null && clientDetailsResult.message.profile_picture != undefined && clientDetailsResult.message.profile_picture != "") {
                setProfile(prevState => ({ ...prevState, uri: clientDetailsResult.message.profile_picture }))
            }

            if (clientDetailsResult.message.signature_image != null && clientDetailsResult.message.signature_image != undefined && clientDetailsResult.message.signature_image != "") {
                setSignature(prevState => ({ ...prevState, uri: clientDetailsResult.message.signature_image }))
            }
        }
    }, [])

    useEffect(() => {
        // if (requestStatus === STATUS.SUCCESS) {
        //     //dropDownAlertRef.alertWithType('success', 'success', 'Congratulation');
        //     toast.success("Loan submitted successful")
        //     setTimeout(() => {
        //         navigate(NAVIGATE_TO_HOMECLIENTPAGE, { replace: true })
        //     }, 1000)
        //     dispatch(loanApprovalReset());
        // }
        // else if (requestStatus === STATUS.ERROR) {
        //     toast.error(requestErrorMessage)
        //     dispatch(loanApprovalReset());
        // }

        if (underWritingStatus === STATUS.SUCCESS) {
            toast.success("Loan request submited successful")
            setTimeout(() => {
                navigate(NAVIGATE_TO_HOMECLIENTPAGE)
            }, 1000)
            dispatch(clientDetailsRequest(loginResult.token))
            dispatch(underwritingReset());
            dispatch(uploadProfilePhotoReset())
            dispatch(uploadSignatureReset())
        }
        else if (underWritingStatus === STATUS.ERROR) {
            toast.error(underWritingErrorMessage)
            dispatch(underwritingReset());
            dispatch(uploadProfilePhotoReset())
            dispatch(uploadSignatureReset())
        }

        if (termsStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_TERMSANDCONDITIONPAGE, {
                state: {
                    content: termsResult.content
                }
            })
            dispatch(resetTermsAndConditionRequest());
        }
        else if (termsStatus === STATUS.ERROR) {
            toast.error(termsErrorMessage)
            dispatch(resetTermsAndConditionRequest());
        }
    }, [underWritingStatus, termsStatus])

    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            incomeSrc: incomeSrc,
            incomeAmount: incomeAmount,
            loanUse: loanUse.data,
            guarantorName: formElements.guarantorName.value,
            guarantorNumber: formElements.guarantorNumber.value,
            guarantorRelation: formElements.guarantorRelation.value,
            checkBox: formElements.checkBox.checked
            // persistent: formElements.persistent.checked,
        };

        const validateInputs = () => {
            if (data.incomeAmount == null) {
                toast.warn("Please enter your income amount")
            } else if (data.incomeSrc == "") {
                toast.warn("Please select or enter your income source")
            } else if (data.loanUse == "") {
                toast.warn("Please select or enter your loan use plan")
            } else if (collateralFields[0].collateral_name == "" || collateralFields[0].collateral_value == "" || collateralFields[0].collateral_location.latitude == 0 || collateralFields[0].collateral_picture.uri == "") {
                toast.warn("Please fill all collateral input fields, picture and location")
            } else if (data.guarantorName == "" || data.guarantorNumber == "" || data.guarantorRelation == "") {
                toast.warn("Please fill all gurrantor input fields")
            } else if (data.guarantorNumber.length < 10) {
                toast.warn("Please enter valid gurrantor mobile number")
            } else if (!data.checkBox) {
                toast.warn("Select check box to accept our Terms and Conditions")
            } else if (profile.uri == null) {
                toast.warn("Please upload your picture")
            } else if (signature.uri == null) {
                toast.warn("Your signature is required")
            } else {
                toast.warn("Please fill all  fields below.")
            }
        }
        // alert(JSON.stringify(data));
        let valid = data.checkBox && data.guarantorName && data.guarantorNumber && profile.uri != null && signature.uri != null &&
            data.guarantorRelation && data.incomeAmount && data.incomeSrc && data.loanUse &&
            collateralFields[0].collateral_name != "" && collateralFields[0].collateral_value != ""
            && collateralFields[0].collateral_location.latitude != 0 && collateralFields[0].collateral_picture.uri != ""
        if (valid) {
            dispatch(underwritingRequest(loginResult.token, ambassador_tile, loanAmount, duration, selectedPlan, data.incomeSrc, data.incomeAmount,
                data.loanUse, collateralFields, data.guarantorName, data.guarantorNumber, data.guarantorRelation))
            if (profile.file != null) {
                dispatch(uploadProfilePhoto(loginResult.token, profile.file))
            }
            if (signature.file != null) {
                dispatch(uploadSignatureRequest(loginResult.token, signature.file))
            }

        } else {
            validateInputs()
        }
    }

    // function to handle income source selections
    const handleIncomeSrc = (event, newValue) => {
        if (newValue === "Other") {
            setExtendSrc(true)
            setIncomeSrc("")
        } else {
            setExtendSrc(false)
            setIncomeSrc(newValue)
        }
    }

    // function to handle loan use list selections
    const handleLoanUse = (event, newValue) => {
        if (newValue === "Other") {
            setLoanUse({
                data: "",
                extend: true
            })
        } else {
            setLoanUse({
                data: newValue,
                extend: false
            })
        }
    }

    // FUNCTION TO GET LOCATION
    const getLocation = (index) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const updatedFields = [...collateralFields];
                    updatedFields[index]['collateral_location'] = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                    setCollateralFields(updatedFields);
                },
                (error) => {
                    console.error('Error getting location:', error.message);
                    // alert(`geolocation Error: ${JSON.stringify(error)}`)
                    // toast.error(`geolocation Error: ${error.message}`)

                }
            );
        }
        else {
            alert("Geolocation is not supported with this browser, please use another browser for completion")
        }
    }

    // FUNCTION TO GET PICTURE 
    const handlePicture = (event, index) => {
        console.log("Picture pros: ", event.target.files)
        const updatedFields = [...collateralFields];
        updatedFields[index]['collateral_picture'] = {
            uri: URL.createObjectURL(event.target.files[0]),
            file: event.target.files[0]
        };
        setCollateralFields(updatedFields);
    }

    // FUNCTION TO ADD, REMOVE AND HANDLE COLLATERAL INPUTS
    const addCollateralData = () => {
        setCollateralFields([...collateralFields,
        {
            collateral_name: '',
            collateral_value: '',
            collateral_picture: {
                uri: "",
                file: null
            },
            collateral_location: {
                latitude: 0,
                longitude: 0
            }
        }])
    }

    const removeCollateralData = (index) => {
        const updateData = [...collateralFields]
        updateData.splice(index, 1);
        setCollateralFields(updateData)
    }

    const handleCollateralChange = (index, field, text) => {
        const updatedFields = [...collateralFields];
        updatedFields[index][field] = text;
        setCollateralFields(updatedFields)

    }

    // FUNCTION TO FORMAT AMOUNT  INTO THOUSAND SEPARATOR
    const formatInputValue = (value) => {
        // Remove non-numeric characters from the input value
        const numericValue = value.replace(/\D/g, '');
        // Format the numeric value with a thousand separator
        const formattedValue = parseInt(numericValue).toLocaleString('en-US');

        return formattedValue;
    };

    const viewTerms = () => {
        //navigation.navigate(NAVIGATION_LOAN_TERMS_SCREEN);
        let language = 'sw';
        dispatch(termsAndConditionRequest(language));
    }

    // function to check loading status and render loading view
    const checkLoading = () => {
        if (underWritingStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    const hd = () => {
        picRef.current.click();
    }

    return (
        <Box>
            <PageTitle title={'Loan Underwriting'} />
            {/* <ToastContainer /> */}

            <LoadingView loading={checkLoading()} />
            <Card variant="outlined" sx={{ backgroundColor: 'background.level1', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography level="title-lg" sx={{ color: 'background.appcolor', textAlign: 'center' }}>Please fill all fields bellow</Typography>

                    <AspectRatio
                        variant="outlined"
                        ratio="1"
                        sx={{ width: 150, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }}>
                        <img src={profile.uri} loading="lazy" alt={profile.uri == "" ? "Change Profile" : null} />
                        <input type="file" id="profile-file" hidden
                            onChange={(event) => setProfile(
                                (prevProfile) => ({
                                    ...prevProfile,
                                    uri: URL.createObjectURL(event.target.files[0]),
                                    file: event.target.files[0],
                                }))} />
                        <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="soft"
                            color="neutral"
                            sx={{
                                position: 'absolute',
                                zIndex: 20,
                                borderRadius: 8,
                                right: 0,
                                bottom: 0,
                                boxShadow: 'sm',
                            }}
                        >
                            {/* <EditRounded /> */}
                            <label htmlFor="profile-file" style={{ width: '100%' }}><EditRounded color="primary" /></label>
                        </IconButton>

                    </AspectRatio>
                    <AspectRatio
                        variant="outlined"
                        ratio="4"
                        sx={{ width: 150, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }}>
                        {signature.uri != "" ? <img src={signature.uri} loading="lazy" /> : <label htmlFor="sign-file" style={{ color: 'green' }}>Add Signature</label>}
                        <input type="file" id="sign-file" hidden
                            style={{ display: 'none' }}
                            onChange={(event) => setSignature(
                                (prevProfile) => ({
                                    ...prevProfile,
                                    uri: URL.createObjectURL(event.target.files[0]),
                                    file: event.target.files[0],
                                }))} />
                        {signature.uri != "" && <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="soft"
                            color="neutral"
                            sx={{
                                position: 'absolute',
                                height: '100%',
                                zIndex: 20,
                                borderRadius: 8,
                                right: 0,
                                bottom: 0,
                                boxShadow: 'sm',
                            }}
                        >
                            <label htmlFor="sign-file" style={{ width: '100%', alignItems: 'center' }}><EditRounded /></label>
                        </IconButton>}
                    </AspectRatio>

                    <Typography level="body-sm" >Click question to open input field and make sure you fill all field with validy information</Typography>

                    {/* form start */}
                    <Stack component='form' onSubmit={handleSubmit} noValidate gap={3} maxWidth={800} sx={{ mt: 1 }}>
                        <AccordionGroup sx={{ gap: 1 }}>
                            {/* income source */}
                            <Accordion>
                                <AccordionSummary>Please verify your source of income</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <Select placeholder="Select Income source" onChange={handleIncomeSrc} required>
                                            <Option value="Employment">Employment</Option>
                                            <Option value="Self-Employed/Enterpreneur">Self-employed/Enterpreneur</Option>
                                            <Option value="Freelancer/Contractor">Freelancer/Contractor</Option>
                                            <Option value="Bussiness Owner">Bussiness Owner</Option>
                                            <Option value="Pension/Retirement Income">Pension/Retirement Income</Option>
                                            <Option value="Investments/Dividends">Investment/Dividends</Option>
                                            <Option value="Student (with stipend or support)">Student (with stipend or support)</Option>
                                            <Option value="Other">Other (Please specify)</Option>
                                        </Select>
                                        <FormLabel></FormLabel>
                                        {extendScr && <Input type="text" value={incomeSrc} onChange={(e) => setIncomeSrc(e.target.value)} required />}
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            {/* income amount */}
                            <Accordion>
                                <AccordionSummary>What is your Monthly income in TZS</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel></FormLabel>
                                        <Input
                                            type="text"
                                            value={incomeAmount}
                                            onChange={(event) => setIncomeAmount(formatInputValue(event.target.value))}
                                            name="incomeAmount"
                                            startDecorator={"Tsh."}
                                            required />
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            {/* loan use */}
                            <Accordion>
                                <AccordionSummary>How will you use the loan you are acquiring from sava</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <Select placeholder="Select loan use" onChange={handleLoanUse} required>
                                            <Option value="Investments">Investments</Option>
                                            <Option value="Business Expenses">Business Expenses</Option>
                                            <Option value="Home Improvement">Home Improvement</Option>
                                            <Option value="Debt Consolidation">Debt Consolidation</Option>
                                            <Option value="Education Expenses">Education Expenses</Option>
                                            <Option value="Medical Expenses">Medical Expenses</Option>
                                            <Option value="Travel Expenses">Travel Expenses</Option>
                                            <Option value="Emergency Expenses">Emergency Expenses</Option>
                                            <Option value="Personal Development">Personal Development</Option>
                                            <Option value="Technology and Gadgets">Technology and Gadgets</Option>
                                            <Option value="Legal Expenses">Legal Expenses</Option>
                                            <Option value="Insurance Premiums">Insurance Premiums</Option>
                                            <Option value="Other">Other (Please specify)</Option>
                                        </Select>
                                        <FormLabel></FormLabel>
                                        {loanUse.extend && <Input type="text" value={loanUse.data} onChange={(e) => setLoanUse(prevData => ({ ...prevData, data: e.target.value }))} required />}
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            {/* collateral */}
                            <Accordion>
                                <AccordionSummary>What collatera amount are you provide for this loan? Please fill the name and its value</AccordionSummary>
                                <AccordionDetails>
                                    {collateralFields.map((field, index) => (
                                        <Box key={index}>
                                            <FormControl>
                                                <FormLabel>Collateral Name</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={field.collateral_name}
                                                    onChange={(event) => handleCollateralChange(index, 'collateral_name', event.target.value)}
                                                    placeholder="Enter collateral name"
                                                    required />

                                                <FormLabel>Value (TZS)</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={field.collateral_value}
                                                    onChange={(event) => handleCollateralChange(index, 'collateral_value', formatInputValue(event.target.value))}
                                                    // slotProps={{input: {component: NumericFormatAdapter}}}
                                                    startDecorator={"Tsh."}
                                                    required />
                                            </FormControl>

                                            <Box
                                                sx={{
                                                    display: { xs: 'block', md: 'flex' },
                                                    // m: 1,
                                                    // justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                <FormControl>
                                                    <FormLabel>Choose Collateral Image</FormLabel>
                                                    <input type="file" id="col-picture" hidden onChange={(event) => handlePicture(event, index)} />

                                                    <Input
                                                        type="file"
                                                        startDecorator={
                                                            <Button variant="soft" color="neutral" startDecorator={<PictureInPictureAltRounded />}>
                                                                <label htmlFor="col-picture">Choose Picture</label>
                                                            </Button>
                                                        }
                                                        sx={{
                                                            // Define internal styles for the file input
                                                            '& input[type="file"]::file-selector-button': {
                                                                display: 'none',
                                                            },
                                                        }}
                                                        onChange={(event) => handlePicture(event, index)} required />
                                                    <FormLabel>Your Collateral Location</FormLabel>
                                                    <Input
                                                        readOnly
                                                        placeholder={field.collateral_location.latitude == 0 ? "Click to collateral item location" : `lat: ${field.collateral_location.latitude} long: ${field.collateral_location.longitude}`}
                                                        // value={location.latitude}
                                                        startDecorator={
                                                            <Button variant="soft" color="neutral" startDecorator={<LocationOn />}>
                                                                Locate
                                                            </Button>
                                                        }
                                                        onClick={() => getLocation(index)}
                                                    />
                                                </FormControl>
                                                {(field.collateral_picture.uri != "" || field.collateral_location.latitude != 0) &&
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1, gap: 1 }}>
                                                        {field.collateral_picture.uri &&
                                                            <AspectRatio
                                                                variant="outlined"
                                                                ratio="1"
                                                                sx={{ width: 150, borderRadius: 4 }}
                                                            >
                                                                <img src={field.collateral_picture.uri} loading="lazy" alt="" />
                                                            </AspectRatio>}
                                                        {field.collateral_location.latitude != 0 &&
                                                            <AspectRatio
                                                                variant="outlined"
                                                                ratio="1"
                                                                sx={{ width: 150, borderRadius: 4 }}
                                                            >
                                                                <MapContainer latitude={field.collateral_location.latitude} longitude={field.collateral_location.longitude} />
                                                            </AspectRatio>}
                                                    </Box>}


                                            </Box>

                                            <ButtonGroup sx={{ mt: 1 }}>
                                                {field.collateral_location.latitude != 0 &&
                                                    <Button onClick={addCollateralData} variant="solid" color="primary">+ Add</Button>}
                                                {collateralFields.length > 1 &&
                                                    <Button onClick={() => removeCollateralData(index)} variant="solid" color="danger">- Remove</Button>}
                                            </ButtonGroup>

                                            <Divider sx={{ my: 2 }} />
                                        </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>

                            {/* guarantor */}
                            <Accordion>
                                <AccordionSummary>What would you like Sava Loan to contact as your Loan Guarantor</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input type="text" name="guarantorName" placeholder="Enter your guarantor name" required />

                                        <FormLabel>Phone Number</FormLabel>
                                        <Input type="tel" name="guarantorNumber" placeholder="ex: 0688000001" required />

                                        <FormLabel>Your relationship</FormLabel>
                                        <Input type="text" name="guarantorRelation" placeholder="Enter your relationship with guarantor" required />
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>

                        {/* checkboc for agrement */}
                        <Checkbox
                            size="md"
                            label="I agree Sava Loan to review and verifying the information I provided before approving this loan."
                            name="checkBox" />
                        <Link onClick={viewTerms} sx={{ color: 'green' }}>Terms and Conditions</Link>
                        {/* <Typography component='a' onClick={null}>Terms and Conditions</Typography> */}

                        <Button fullWidth type="submit">APPLY LOAN</Button>
                    </Stack>

                </CardContent>
            </Card>
        </Box>
    )
}

const mapPropsToState = ({ authClient, account, guest, loan }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        loanRequestStatus: requestStatus,
        loanRequestErrorMessage: requestErrorMessage,
        loanRequestResult: requestResult,

        underWritingStatus: underWritingStatus,
        underWritingErrorMessage: underWritingErrorMessage,
        underWritingResult: underWritingResult,
    } = loan
    const {
        clientDetailsStatus: clientDetailsStatus,
        clientDetailsResult: clientDetailsResult,
    } = account
    const {
        termsAndConditionResult: termsResult,
        termsAndConditionStatus: termsStatus,
        termsAndConditionErrorMessage: termsErrorMessage
    } = guest

    return {
        loginResult,
        requestStatus,
        requestErrorMessage,
        requestResult,
        termsResult,
        termsStatus,
        termsErrorMessage,

        clientDetailsStatus,
        clientDetailsResult,

        underWritingStatus,
        underWritingErrorMessage,
        underWritingResult,
    }
}

export default connect(mapPropsToState, {})(LoanUnderWritingPage)