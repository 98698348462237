import React, { forwardRef } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}


const NidaFormat = React.forwardRef<PatternFormatProps, CustomProps>(
    function NidaFormat(props, ref) {
        const { onChange, ...other } = props;

        return (
            <PatternFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                format='########-#####-#####-##'
                valueIsNumericString
                // mask={''}
            />
        );
    },
);

export default NidaFormat;
