// export const API_BASE = 'https://sava-api.tbridgetech.com';
// export const API_BASE = 'http://192.168.88.13:8081';

export const API_BASE = 'https://sava-api.tbridge.africa'

// export const API_BASE = 'https://a0180b01-c44d-4f93-b98b-3426584c3699.mock.pstmn.io'

//api end points for client
export const LOGIN_CLIENT_URL = '/auth/login';
export const CLIENT_OTP_URL = '/auth/verify';
export const AMBASSADOR_VALIDATE_URL =  '/resource/confirm-ambassador';
export const UNDERWRINTING_URL = '/resource/loan-request-with-underwriting';
export const LOAN_APPROVAL_URL = '/resource/loan-request';
export const REGISTRATION_URL = '/auth/create';
export const OTP_URL = '/auth/mobile-verify';
export const UPDATE_OTP_URL = '/auth/resend-code';
export const UPLOAD_PROFILE_PHOTO_URL = '/resource/change-profile';
export const UPLOAD_SIGNATURE_URL = '/resource/save-signature';
export const LOAN_HISTORY_URL = '/resource/loan-history';
export const CHANGE_USER_PIN_URL = '/resource/change-password';
export const SUPPORT_CONTENT_URL = '/resource/customer-support';
export const PAYMENT_METHOD_URL = '/resource/customer-support';
export const PENDING_LOAN_DETAIL_URL = '/resource/pending-loan-details';
export const ACTIVE_LOAN_DETAIL_URL = '/resource/active-loan-details';
export const PUSH_PAYMENT_URL = '/resource/selcom-loan-payment';
export const TRANSACTION_HISTORIES_URL = '/resource/transaction-history';
export const TERMS_AND_CONDITION_URL = '/auth/loan-terms';
export const SEND_CLIENT_CHAT_URL = '/resource/chat-message';
export const CLIENT_CHAT_HISTORY_URL = '/resource/chat-history';
export const CLIENT_NOTIFICATION_URL = '/resource/notification';
export const FORGET_PASSWORD_URL = '/auth/forget-password';
export const CLIENT_DETAILS_URL = '/resource/my-details'