import React, { useEffect, useState } from "react";
import { AspectRatio, Box, Button, Card, CardContent, CircularProgress, DialogContent, DialogTitle, FormControl, FormLabel, IconButton, Input, Modal, ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";
import { toast } from 'react-toastify';
import { ClientMain, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { EditRounded } from "@mui/icons-material";
import { API_BASE, STATUS } from "../../../constant";

import {
    clientDetailsRequest,
    clientDetailsReset,
    changeUserPinRequest,
    changeUserPinReset,
} from "../../../store/actions"

interface FormElements extends HTMLFormControlsCollection {
    oldPassword: HTMLInputElement;
    newPassrword: HTMLInputElement;
    confirmPassword: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const ClientProfilePage = ({
    loginResult,

    changeUserPinStatus,
    changeUserPinResult,
    changeUserPinErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,
    clientDetailsErrorMessage
}) => {

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [profile, setProfile] = useState('');
    const [phone, setPhone] = useState('');
    const [nida, setNida] = useState('')

    const [loading, setLoading] = useState(false)
    const [passModal, setPassModal] = useState(false)

    useEffect(() => {
        if (clientDetailsStatus === STATUS.SUCCESS) {
            // console.log("CLIENT DATA: ", clientDetailsResult)
            setName(clientDetailsResult.message.name);
            setPhone(clientDetailsResult.message.mobile_number);
            setNida(clientDetailsResult.message.nida);
            setProfile(clientDetailsResult.message.profile_picture);
        } else if (clientDetailsStatus === STATUS.ERROR) {
            dispatch(clientDetailsReset())
        }

        if (changeUserPinStatus === STATUS.SUCCESS) {
            toast.success("Password changed successful")
            setLoading(false);
            setPassModal(false);
            dispatch(changeUserPinReset())

        } else if (changeUserPinStatus === STATUS.ERROR) {
            setLoading(false);
            dispatch(changeUserPinReset())
            toast.error(changeUserPinErrorMessage)
        }
    }, [clientDetailsStatus, changeUserPinStatus])

    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            newPassword: formElements.newPassword.value,
            oldPassword: formElements.oldPassword.value,
            confirmPassword: formElements.confirmPassword.value,
        };
        console.log(data)
        if (data.newPassword && data.oldPassword && data.confirmPassword) {
            if (data.newPassword === data.confirmPassword) {
                setLoading(true)
                dispatch(changeUserPinRequest(loginResult.token, data.oldPassword, data.newPassword, data.confirmPassword));
            } else {
                toast.error('password does not match.')
            }
        } else {
            toast.error("Please insert all inputs")
        }

    }

    return (
        <Box>
            <PageTitle title={'Profile'} />

            <Card sx={{ maxWidth: 800, alignSelf: 'center', display: 'flex', m: 'auto' }}>
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <AspectRatio
                            ratio="1"
                            sx={{ width: 100, height: 100, borderRadius: '100%', boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.3)', }}
                        >
                            <img
                                src={profile}
                                loading="lazy"
                                alt=""
                            />
                            {/* <IconButton
                                    aria-label="upload new picture"
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    sx={{
                                        bgcolor: 'background.body',
                                        position: 'absolute',
                                        zIndex: 20,
                                        borderRadius: '50%',
                                        right: 0,
                                        bottom: 0,
                                        boxShadow: 'sm',
                                    }}
                                >
                                    <EditRounded />
                                </IconButton> */}
                        </AspectRatio>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                // alignItems: 'center',
                                m: 'auto'
                            }}>
                            <Typography level="title-md">
                                {name.toLowerCase() // convert uppercase name to firstuppercase
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}
                            </Typography>
                            <Typography level="body-md">+{phone}</Typography>
                            {/* <Typography level="body-md">{nida}</Typography> */}
                            <Button
                                variant="outlined"
                                size="sm"
                                sx={{ borderRadius: 30, mt: 1, color: 'background.appcolor' }}
                                onClick={() => setPassModal(true)}>
                                Change Password
                            </Button>
                        </Box>

                    </Stack>
                </CardContent>

            </Card>

            {/* password change modal */}
            <Modal open={passModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {  // ----------------> FOR MOBILE
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" component="button" onClick={() => setPassModal(false)} />
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>Please enter old password and new password twice to verify.</DialogContent>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            <FormLabel>Old Password</FormLabel>
                            <Input
                                autoFocus
                                type="password"
                                name="oldPassword"
                                required
                            />
                        </FormControl>
                        <FormControl sx={{ mt: 1 }}>
                            <FormLabel>New Password</FormLabel>
                            <Input
                                autoFocus
                                type="password"
                                name="newPassword"
                                required
                            />
                        </FormControl>
                        <FormControl sx={{ pb: 1 }}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input
                                autoFocus
                                type="password"
                                name="confirmPassword"
                                required
                            />
                        </FormControl>

                        <Button
                            startDecorator={loading && <CircularProgress />}
                            type="submit"
                            // onClick={verifyOTP}
                            fullWidth>
                            {loading ? "LOADING..." : "CONFIRM"}
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>
        </Box>
    )
}

const mapStateToProps = ({ authClient, account }) => {
    const {
        otpResult: loginResult,
        changeUserPinResult: changeUserPinResult,
        changeUserPinStatus: changeUserPinStatus,
        changeUserPinErrorMessage: changeUserPinErrorMessage
    } = authClient
    const {
        clientDetailsStatus: clientDetailsStatus,
        clientDetailsResult: clientDetailsResult,
        clientDetailsErrorMessage: clientDetailsErrorMessage
    } = account

    return {
        loginResult,

        changeUserPinStatus,
        changeUserPinResult,
        changeUserPinErrorMessage,

        clientDetailsStatus,
        clientDetailsResult,
        clientDetailsErrorMessage
    }
}

export default connect(mapStateToProps, {})(ClientProfilePage)