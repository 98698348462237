import "./App.css";
import {
  Navigate,
  Outlet,
} from "react-router-dom";
// import PublicRoute from './route/Mainroute'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence } from "framer-motion";

import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";

import RoutesContainer from './route/Routes'

import {
  clientLogoutRequest,
  clientDetailsRequest,
  transactionHistoryRequest,
  loanHistoryRequest} from "./store/actions"
import { toast } from 'react-toastify';
import { STATUS } from './constant';


import {
  NAVIGATE_TO_LOGINPAGE,
} from "./route/types";

//import MainRoute from './route/Mainroute';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// FUNCTION TO CHECK TOKEN
const parseJwt = (token) => {
  try {
    let jwtFirstPart = token.split(".")[1];
    //console.log(jwtFirstPart);
    return JSON.parse(atob(jwtFirstPart));
  } catch (error) {
    console.log(error);
    return null;
  }
};

// MAIN TO RENDER ROUTES AND PAGES
const App = ({

  clientLoginStatus,
  clientLoginResult,
  clientLoginErrorMessage,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientLoginStatus === STATUS.SUCCESS) {
      console.log('I am client')
      dispatch(clientDetailsRequest(clientLoginResult.token))
      dispatch(loanHistoryRequest(clientLoginResult.token))
      dispatch(transactionHistoryRequest(clientLoginResult.token));
      checkTokenValidity(parseJwt(clientLoginResult.token));
    }
  }, [clientLoginStatus]);

  const checkTokenValidity = (decodedJwt) => {
    if (!decodedJwt) {
      toast.warn("Not enabled User");
      // Analytics.trackEvent(
      //     "client logged out by the system - invalid JWT", {
      //     "name": clientName,
      //     "mobile": clientMobile,
      //     "created_at": Date().toString()
      //    });
      dispatch(clientLogoutRequest());
      return;
    }

    if (decodedJwt.exp * 1000 < Date.now()) {
      // Analytics.trackEvent(
      //     "client logged out by the system - Session timeout", {
      //     "name": clientName,
      //     "mobile": clientMobile,
      //     "created_at": Date().toString()
      //    });
      toast.warn("User access timeout please login");
      dispatch(clientLogoutRequest());
    }
    return;
  };

  return (
    <>
      <AnimatePresence mode="wait">
        <RoutesContainer loginStatus={clientLoginStatus}/>
      </AnimatePresence>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
};

const mapStateToProps = ({authClient, user }) => {

  const {
    otpStatus: clientLoginStatus,
    otpResult: clientLoginResult,
    otpErrorMessage: clientLoginErrorMessage,
  } = authClient;

  const { userTypes: userType } = user;

  return {

    clientLoginStatus,
    clientLoginResult,
    clientLoginErrorMessage,

    userType,
  };
};
export default connect(mapStateToProps, {})(App);

// export default App;
