import { call, put, takeLatest } from 'redux-saga/effects';
import { CLIENT } from "../../constant";
import { doChangeUserPin, doClientVerifyOTP, doUserLogin } from "../../service/auth";
import errorMessage from "../../utils/client_error.json";


function* userLoginTask(action) {
    try {
        yield put({ type: CLIENT.USER_LOGIN_LOADING });

        const { payload } = action

        const res = yield call(doUserLogin, payload.username, payload.password, payload.email, payload.fcm_token );

        if (res.status == 200) {
            //console.log(res.data)
            yield put({
                type: CLIENT.USER_LOGIN_SUCCESS,
                payload: res.data //save all data to payload
            })

        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.USER_LOGIN_FAILURE,
                payload: res.message
            })
        }        
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.USER_LOGIN_FAILURE,
            payload: errMsg
        })
    }
}

function* otpClientVerifyTask(action) {
    try {
        yield put({ type: CLIENT.CLIENT_OTP_LOADING });

        const { payload } = action;

        const res = yield call(doClientVerifyOTP, payload.username, payload.OTP);

        if (res.status == 200) {
            yield put({
                type: CLIENT.CLIENT_OTP_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.CLIENT_OTP_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code] : errorMessage[4000];
        yield put({
            type: CLIENT.CLIENT_OTP_FAILURE,
            payload: errMsg
        })
    }
}

function* changeUserPinTask(action) {
    try {
        yield put({ type: CLIENT.CHANGE_USER_PIN_LOADING });

        const { payload } = action;

        const res = yield call(doChangeUserPin, payload.token, payload.old_pass, payload.new_pass, payload.confirm_pass);

        if (res.status == 200) {
            yield put({
                type: CLIENT.CHANGE_USER_PIN_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.CHANGE_USER_PIN_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.CHANGE_USER_PIN_FAILURE,
            payload: errMsg
        })
    }
}

function* authClientSaga() {
    yield takeLatest(CLIENT.USER_LOGIN_REQUEST, userLoginTask);
    yield takeLatest(CLIENT.CLIENT_OTP_REQUEST, otpClientVerifyTask);
    yield takeLatest(CLIENT.CHANGE_USER_PIN_REQUEST, changeUserPinTask);
}

export default authClientSaga;