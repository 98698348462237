import { CLIENT, STATUS } from "../../constant";

const INITIAL_STATE = {
    ambassadorValidateStatus: STATUS.DEFAULT,
    ambassadorValidateErrorMessage: "",
    ambassadorResult: null,

    loanRequestStatus: STATUS.DEFAULT,
    loanRequestErrorMessage: "",
    loanRequestResult: null,

    loanHistoryRequestStatus: STATUS.DEFAULT,
    loanHistoryRequestErrorMessage: "",
    loanHistoryRequestResult: [],

    pendingLoanDetailStatus: STATUS.DEFAULT,
    pendingLoanDetailResult: null,
    pendingLoanDetailErrorMessage: "",

    underWritingStatus: STATUS.DEFAULT,
    underWritingResult: null,
    underWritingErrorMessage: "",

    activeLoanDetailStatus: STATUS.DEFAULT,
    activeLoanDetailResult: null,
    activeLoanDetailErrorMessage: "",

    initiatePushPaymentStatus: STATUS.DEFAULT,
    initiatePushPaymentResult: null,
    initiatePushPaymentErrorMessage: ""
}

export default (state = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case CLIENT.AMBASSADOR_VALIDATE_LOADING:
            return {
                ...state,
                ambassadorValidateStatus: STATUS.LOADING,
                ambassadorValidateErrorMessage: "",
                ambassadorResult: null
            }
        case CLIENT.AMBASSADOR_VALIDATE_SUCCESS: 
            return {
                ...state,
                ambassadorValidateStatus: STATUS.SUCCESS,
                ambassadorValidateErrorMessage: "",
                ambassadorResult: payload
            }
        case CLIENT.AMBASSADOR_VALIDATE_FAILURE: 
            return {
                ...state,
                ambassadorValidateStatus: STATUS.ERROR,
                ambassadorValidateErrorMessage: payload,
                ambassadorResult: null
            }
        case CLIENT.AMBASSADOR_VALIDATE_RESET: 
            return {
                ...state,
                ambassadorValidateStatus: STATUS.DEFAULT,
                ambassadorValidateErrorMessage: "",
                ambassadorResult: null
            }

            case CLIENT.UNDERWRITING_LOADING:
                return {
                    ...state,
                    underWritingStatus: STATUS.LOADING,
                    underWritingErrorMessage: "",
                    underWritingResult: null
                }
            case CLIENT.UNDERWRITING_SUCCESS:
                return {
                    ...state,
                    underWritingStatus: STATUS.SUCCESS,
                    underWritingErrorMessage: "",
                    underWritingResult: payload
                }
            case CLIENT.UNDERWRITING_FAILURE:
                return {
                    ...state,
                    underWritingStatus: STATUS.ERROR,
                    underWritingErrorMessage: payload,
                    underWritingResult: ""
                }
            case CLIENT.UNDERWRITING_RESET:
                return {
                    ...state,
                    underWritingStatus: STATUS.DEFAULT,
                    underWritingErrorMessage: "",
                    underWritingResult: null
                }

        case CLIENT.LOAN_APPROVAL_LOADING:
            return {
                ...state,
                loanRequestStatus: STATUS.LOADING,
                loanRequestErrorMessage: "",
                loanRequestResult: null
            }
        case CLIENT.LOAN_APPROVAL_SUCCESS: 
            return {
                ...state,
                loanRequestStatus: STATUS.SUCCESS,
                loanRequestErrorMessage: "",
                loanRequestResult: payload
            }
        case CLIENT.LOAN_APPROVAL_FAILURE: 
            return {
                ...state,
                loanRequestStatus: STATUS.ERROR,
                loanRequestErrorMessage: payload,
                loanRequestResult: ""
            }
        case CLIENT.LOAN_APPROVAL_RESET: 
            return {
                ...state,
                loanRequestStatus: STATUS.DEFAULT,
                loanRequestErrorMessage: "",
                loanRequestResult: null
            }
        case CLIENT.LOAN_HISTORY_LOADING: 
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.LOADING,
                loanHistoryRequestErrorMessage: "",
                loanHistoryRequestResult: []
            }   
        case CLIENT.LOAN_HISTORY_SUCCESS: 
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.SUCCESS,
                loanHistoryRequestErrorMessage: "",
                loanHistoryRequestResult: payload
            }
        case CLIENT.LOAN_HISTORY_FAILURE: 
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.ERROR,
                loanHistoryRequestErrorMessage: payload,
                loanHistoryRequestResult: []
            }
        case CLIENT.LOAN_HISTORY_RESET: 
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.DEFAULT,
                loanHistoryRequestErrorMessage: "",
                loanHistoryRequestResult: []
            }
        case CLIENT.PENDING_LOAN_DETAIL_LOADING:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.DEFAULT,
            }
        case CLIENT.PENDING_LOAN_DETAIL_SUCCESS: 
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.SUCCESS,
                pendingLoanDetailResult: payload,
                pendingLoanDetailErrorMessage: ""
            }
        case CLIENT.PENDING_LOAN_DETAIL_FAILURE:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.ERROR,
                pendingLoanDetailResult: null,
                pendingLoanDetailErrorMessage: payload
            }
        case CLIENT.PENDING_LOAN_DETAIL_RESET:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.DEFAULT,
                pendingLoanDetailResult: null,
                pendingLoanDetailErrorMessage: ""
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_LOADING: 
            return {
                ...state,
                activeLoanDetailStatus: STATUS.LOADING,
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_SUCCESS:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.SUCCESS,
                activeLoanDetailResult: payload,
                activeLoanDetailErrorMessage: ""
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_FAILURE:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.ERROR,
                activeLoanDetailResult: null,
                activeLoanDetailErrorMessage: payload
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_RESET:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.DEFAULT,
                activeLoanDetailResult: null,
                activeLoanDetailErrorMessage: ""
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_LOADING: 
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.LOADING,
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_SUCCESS: 
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.SUCCESS,
                initiatePushPaymentResult: payload,
                initiatePushPaymentErrorMessage: ""
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_FAILURE: 
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.ERROR,
                initiatePushPaymentResult: null,
                initiatePushPaymentErrorMessage: payload
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_RESET: 
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.DEFAULT,
                initiatePushPaymentResult: null,
                initiatePushPaymentErrorMessage: ""
            }
        default:
            return state;
    }
}