import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import {


    userRegistrationRequest, userRegistrationReset,
    otpRegistrationRequest, resetOtpRequest, otpRegistrationReset, resendUserOtpRequest,
    resetTermsAndConditionRequest, termsAndConditionRequest
} from "../../../store/actions"

import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import theme from '../../../utils/theme';
import { Avatar, Card, CircularProgress, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Select, Option } from '@mui/joy';

import { useNavigate } from 'react-router-dom';


import image from '../../../constant/image';
import { NAVIGATE_TO_LOGINPAGE, NAVIGATE_TO_TERMSANDCONDITIONPAGE } from '../../../route/types';
import { toast } from 'react-toastify';
import { STATUS } from '../../../constant';
import { LoadingView } from '../../../components';
import { NidaFormat } from '../../../utils';

interface FormElements extends HTMLFormControlsCollection {
    firstName: HTMLInputElement;
    secondName: HTMLInputElement;
    lastName: HTMLInputElement;
    mobile: HTMLInputElement;
    nida: HTMLInputElement;
    password: HTMLInputElement;
    confirmPassword: HTMLInputElement;
    checkBox: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

// locations
const locations = require('../../../assets/locations.json');

const RegistrationPage = ({
    termsResult,
    termsStatus,
    termsErrorMessage,
    registrationStatus,
    registrationResult,
    registrationErrorMessage,

    OTPRegStatus,
    OTPRegResult,
    OTPRegErrorMessage,
    resendOtpStatus
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [isAmbassador, setAmbassador] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [nida, setNida] = useState('')

    const [OTPModal, setOTPModal] = useState(false);
    const [loadOTP, setLoadOTP] = useState(false);
    const [OTP, setOTP] = useState('')
    const [mobile, setMobile] = useState('')
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    /* --------------- location (region and district data) ----------- */
    const [regionList, setRegionList] = useState([]);
    const [region, setRegion] = useState('');
    const [districtList, setDistrictList] = useState([]);
    const [district, setDistrict] = useState('');

    useEffect(() => {
        let _regions = [];
        locations.map(val => {
            _regions.push(val.name);
        });
        setRegionList(_regions);
    }, []);

    useEffect(() => {
        //first clear selected district
        setDistrict('')
        locations.find((location) => {
            //return location.name = region
            let _districtList = []
            if (location.name == region) {
                location.districts.map(val => {
                    _districtList.push(val.name);
                })
                setDistrictList(_districtList);
                return;
            }
        });
    }, [region])

    const handleRegion = (event, newValue) => {
        setRegion(newValue)
    }

    const handleDistrict = (event, newValue) => {
        setDistrict(newValue)
    }

    /* ----------- end of location set ------------------ */

    useEffect(() => {
        if (registrationStatus == STATUS.SUCCESS) {
            setTimesOut(0)
            setOTPModal(true)
            startCounting()
            dispatch(userRegistrationReset());
        }
        else if (registrationStatus == STATUS.ERROR) {
            setOTPModal(false)
            setTimesOut(0)
            toast.error(registrationErrorMessage)
            dispatch(userRegistrationReset());
        }

        if (OTPRegStatus == STATUS.SUCCESS) {
            //dropDownAlertRef.alertWithType('success', 'success', translate("otpRegistration.verifyAlert"));
            // ToastAndroid.show(translate("otpRegistration.verifyAlert"), ToastAndroid.LONG);
            setLoadOTP(false)
            toast.success("Successful registration")
            setOTPModal(false)
            navigate(NAVIGATE_TO_LOGINPAGE)
            dispatch(otpRegistrationReset());
        }
        else if (OTPRegStatus == STATUS.ERROR) {
            // dropDownAlertRef.alertWithType('error', translate("alert.error"), errorMessage);
            setLoadOTP(false)
            toast.error(OTPRegErrorMessage)
            dispatch(otpRegistrationReset());
        }

        if (resendOtpStatus == STATUS.SUCCESS) {
            toast.success("otp successfully sent")
            dispatch(resetOtpRequest())
        }
        else if (resendOtpStatus == STATUS.ERROR) {
            toast.error("Failed to create new otp, Try again")
            dispatch(resetOtpRequest())
        }

        if (termsStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_TERMSANDCONDITIONPAGE, {
                state: {
                    content: termsResult.content
                }
            })
            dispatch(resetTermsAndConditionRequest());
        }
        else if (termsStatus === STATUS.ERROR) {
            toast.error(termsErrorMessage)
            dispatch(resetTermsAndConditionRequest());
        }
    })


    // FUNCTION TO HANDLE REGISTRATION FORM SUBMITION
    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            firstName: formElements.firstName.value,
            secondName: formElements.secondName.value,
            lastName: formElements.lastName.value,
            mobile: formElements.mobile.value,
            nida: nida,
            confirmPassword: formElements.confirmPassword.value,
            password: formElements.password.value,
            checkBox: formElements.checkBox.checked,
        };
        console.log("data: ", data, "\n", region, district)
        if (data.firstName != "" && data.lastName != "" && data.nida != "" && data.mobile && data.secondName != "" && data.password != "" && data.checkBox != "" &&
            data.confirmPassword != "" && region != "" && district != "") {
            if (data.password === data.confirmPassword) {
                setMobile(data.mobile)
                // setOTPModal(true)
                // setTimesOut(0)
                // startCounting()
                let name = data.firstName + " " + data.secondName + " " + data.lastName
                dispatch(userRegistrationRequest(name, data.mobile, data.nida, data.password, data.confirmPassword, region, district, ""));
            } else {
                toast.error("Password does not match")
            }

        } else {
            toast.error("Please fill out all fields");
        }
    }

    // FUNCTION TO VERIFY OTP FROM  BACKEND
    const verifyOTP = () => {
        if (OTP) {
            setLoadOTP(true)
            dispatch(otpRegistrationRequest(mobile, OTP));
        } else {
            toast.error('Please enter OTP to verify')
        }

    }

    // FUNCTION TO CHECK AND RESEND OTP
    const resendOtpCode = () => {
        // console.log('Current button state:', isResendDisabled)
        if (isResendDisabled) {
            toast.warn(`${'Otp already sent to your registered mobile ' + mobile + '. \n Please wait for 1 minute before requesting again'}`)
        } else {
            dispatch(resendUserOtpRequest(mobile));
            setIsResendDisabled(true);
            startCounting()
            //disable resend button for 60 seconds again
            // setTimeout(() => {
            //     setIsResendDisabled(false);
            // }, 60000)
        }
    }

    const [timeOut, setTimesOut] = useState(0)

    const startCounting = () => {
        // Set the target time 1 minute from now
        const targetTime = new Date().getTime() + 60000; // Current time + 1 minute in milliseconds

        // Update the countdown every second
        const timer = setInterval(() => {
            // if (timeOut > 0) {
            //     cl
            // }
            // Get the current time
            const currentTime = new Date().getTime();

            // Calculate the remaining time in milliseconds
            const remainingTime = targetTime - currentTime;

            // Calculate minutes and seconds
            const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

            // Display the countdown
            // console.log(`${minutes} minutes ${seconds} seconds`);
            setTimesOut(seconds)

            // If the countdown is finished, clearInterval to stop the timer
            if (remainingTime < 0) {
                clearInterval(timer);
                setTimesOut(0)
                setIsResendDisabled(false)
                console.log("Countdown finished!");
            }
        }, 1000); // Update every second (1000 milliseconds)
    }

    const viewTerms = () => {
        //navigation.navigate(NAVIGATION_LOAN_TERMS_SCREEN);
        let language = 'sw';
        dispatch(termsAndConditionRequest(language));
    }

    // LOADING
    const checkLoading = () => {
        if (termsStatus === STATUS.LOADING || OTPRegStatus === STATUS.LOADING || registrationStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <CssVarsProvider defaultMode="light" disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '600px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />

            {/* loading  */}
            <LoadingView loading={checkLoading()} />

            <Box
                sx={(theme) => ({
                    //   width: '90%',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',

                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        `url(${image.Images.backgroung})`,
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            `url(${image.Images.backgroung2})`,
                    },
                })}
            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        // width:
                        //   'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 4,
                            gap: 1,
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            // flexDirection: 'column',
                        }}
                    >
                        <Avatar
                            src={image.Images.logoWhite}
                            size='sm'
                            sx={{
                                maxWidth: 80, maxHeight: 80, backgroundColor: 'primary.500', padding: '5px',
                            }}
                        />
                        <Typography level='title-lg'>SAVA</Typography>
                    </Box>
                    <Card
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            // width: 400,
                            maxWidth: '100%',
                            mx: 'auto',

                            backgroundColor: 'background.body',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography level="h3">Sign Up</Typography>
                                <Typography level="body-sm">
                                    Already have account?{' '}
                                    <Link href={NAVIGATE_TO_LOGINPAGE} sx={{ color: 'green' }} level="title-sm">
                                        Sign In!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: { xs: '#FFF', md: 'text.tertiary' },
                                    '--Divider-lineColor': {
                                        xs: '#FFF',
                                        md: 'var(--joy-palette-divider)',
                                    },
                                },
                            })}
                        />

                        <Stack component='form' onSubmit={handleSubmit} gap={4} sx={{ mt: 2 }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                                {/* first name */}
                                <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>First Name</FormLabel>
                                    <Input type="text" name="firstName" placeholder='Enter your first name'/>
                                </FormControl>

                                {/* second name */}
                                <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>Middle Name</FormLabel>
                                    <Input type="text" name="secondName" placeholder='Enter your second name'/>
                                </FormControl>

                                {/* last name */}
                                <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>Surname Name</FormLabel>
                                    <Input type="text" name="lastName" placeholder='Enter your third name'/>
                                </FormControl>
                            </Stack>

                            <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                                {/* mobile number */}
                                <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>Mobile number</FormLabel>
                                    <Input type="tel" name="mobile" placeholder='Enter phone number'/>
                                </FormControl>

                                {/* nida */}
                                <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>Nida</FormLabel>
                                    <Input 
                                    value={nida} 
                                    onChange={(e) => setNida(e.target.value)}
                                    name="nida" 
                                    placeholder='Enter nida number' 
                                    slotProps={{input: {component: NidaFormat}}}/>
                                </FormControl>
                            </Stack>

                            <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                                {/* region */}
                                <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>Region</FormLabel>
                                    <Select placeholder="Select your region" onChange={handleRegion} required>
                                        {regionList.map((dt) => (
                                            <Option value={dt}>{dt}</Option>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* district */}
                                {region && <FormControl sx={{ flex: 1 }} required>
                                    <FormLabel>District</FormLabel>
                                    <Select placeholder="Select your district" onChange={handleDistrict} required>
                                        {districtList.map((dt) => (
                                            <Option value={dt}>{dt}</Option>
                                        ))}
                                    </Select>
                                </FormControl>}
                            </Stack>

                            <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                                {/* password */}
                                <FormControl required sx={{ flex: 1 }}>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" name="password" placeholder='********'/>
                                </FormControl>

                                {/* confirm password */}
                                <FormControl required sx={{ flex: 1 }}>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <Input type="password" name="confirmPassword" placeholder='*******'/>
                                </FormControl>
                            </Stack>

                            <Stack gap={4} sx={{ mt: 2 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox size="sm" label="I agree with term and conditions of Sava Loan Tanzania" name="checkBox" />
                                    <Link onClick={viewTerms} level='title-sm' sx={{ color: 'green' }} >
                                        View Terms and Conditions
                                    </Link>
                                </Box>
                                <Button type="submit" fullWidth>
                                    Sign in
                                </Button>
                            </Stack>
                        </Stack>
                    </Card>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center" sx={{ color: 'whitesmoke' }}>
                            © Sava Tanzania {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* OTP MODAL */}
            <Modal open={OTPModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {  // ----------------> FOR MOBILE
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" component="button" onClick={() => setOTPModal(false)} />
                    <DialogTitle>OTP Verification</DialogTitle>
                    <DialogContent>Please enter OTP that has been sent to {mobile} mobile number for verification.</DialogContent>
                    <form
                    // onSubmit={startPush}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            {/* <FormLabel></FormLabel> */}
                            <Input
                                autoFocus
                                type="password"
                                required
                                value={OTP}
                                defaultValue={OTP}
                                placeholder='Insert OTP here'
                                onChange={(event) => setOTP(event.target.value)}
                            />
                        </FormControl>

                        <Button
                            startDecorator={loadOTP && <CircularProgress />}
                            // type="submit"
                            onClick={!loadOTP ? verifyOTP : null}
                            fullWidth>
                            {loadOTP ? "LOADING..." : "CONFIRM"}
                        </Button>

                        <Button
                            // startDecorator={loadOTP && <CircularProgress />}
                            sx={{ mt: 1 }}
                            variant='soft'
                            // type="submit"
                            onClick={resendOtpCode}
                            fullWidth>
                            {!isResendDisabled ? 'Resend' : "Reset OTP in " + timeOut + " seconds"}
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>

        </CssVarsProvider>
    );
}

const mapStateToProps = ({ guest, registration }) => {
    const {
        termsAndConditionResult: termsResult,
        termsAndConditionStatus: termsStatus,
        termsAndConditionErrorMessage: termsErrorMessage
    } = guest
    const {
        userRegistrationStatus: registrationStatus,
        userRegistrationErrorMsg: registrationErrorMessage,
        userRegistrationResult: registrationResult,

        otpRegistrationStatus: OTPRegStatus,
        otpRegistrationErrorMsg: OTPRegErrorMessage,
        otpRegistrationResult: OTPRegResult,
        resendOtpStatus: resendOtpStatus
    } = registration

    return {
        termsResult,
        termsStatus,
        termsErrorMessage,
        registrationStatus,
        registrationResult,
        registrationErrorMessage,

        OTPRegStatus,
        OTPRegResult,
        OTPRegErrorMessage,
        resendOtpStatus
    }
}

export default connect(mapStateToProps, {})(RegistrationPage)