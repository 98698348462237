import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Card, Chip, Stack, Typography, accordionDetailsClasses, accordionSummaryClasses } from "@mui/joy";
import { toast } from 'react-toastify';
import ChatBox, { ChatFrame } from 'react-chat-plugin';

import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";

import { fetchSupportContent, fetchSupportContentReset, clientChatHistoryRequest, clientChatHistoryReset, sendClientChatRequest, sendClientChatReset } from "../../../store/actions"
import { API_BASE, STATUS } from "../../../constant";
import { HelpCenterOutlined, HelpOutline, HelpOutlined, PaidOutlined, Person, QuestionMarkOutlined, QuestionMarkRounded, SmsRounded, WarningRounded } from "@mui/icons-material";
import image from "../../../constant/image";
import { log } from "util";

const renderSupportList = (supportList) => {

    return (
        <AccordionGroup
            variant="soft"
            transition="0.2s"
            sx={{
                maxWidth: 700,
                gap: 1,
                borderRadius: 'lg',
                [`& .${accordionSummaryClasses.button}:hover`]: {
                    bgcolor: 'transparent',
                },
                [`& .${accordionDetailsClasses.content}`]: {
                    boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
                    [`&.${accordionDetailsClasses.expanded}`]: {
                        paddingBlock: '0.75rem',
                    },
                },
            }}
        >
            {supportList.map((data, index) => (
                <Accordion key={index}>
                    <AccordionSummary sx={{ color: 'background.appcolor' }}>{data.title}</AccordionSummary>
                    <AccordionDetails variant="soft">
                        {(data.description.split("\n")).map((paragraph, index) => (
                            <p key={index} style={{ margin: 0, padding: 2, }}>{paragraph}</p>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </AccordionGroup>
    )
}



const ClientSupportPage = ({
    loginResult,
    supportDataResult,
    supportDataStatus,
    supportDataErrorMessage,
    clientChatStatus,
    clientChatResult,
    clientChatErrorMessage,
    sendClientChatStatus,
    sendClientChatErrorMessage
}) => {

    const dispatch = useDispatch()

    const [display, setDisplay] = useState('help');
    const [supportList, setSupportList] = useState([])

    const [chatList, setChatList] = useState([])
    const [messages, setMessages] = useState([])
    const [state, setState] = useState('');

    useEffect(() => {
        if (display === 'help') {
            dispatch(fetchSupportContent(loginResult.token, "sw", display));
        } else if (display === 'payment') {
            dispatch(fetchSupportContent(loginResult.token, "sw", display));
        } else if (display === 'chat') {
            dispatch(clientChatHistoryRequest(loginResult.token));
        }
    }, [display])

    useEffect(() => {
        if (supportDataStatus === STATUS.SUCCESS) {
            // console.log("SUPPORT: ", supportDataResult)
            setSupportList(supportDataResult.content)
            dispatch(fetchSupportContentReset())
        }
        else if (supportDataStatus === STATUS.ERROR) {
            toast.error(supportDataErrorMessage)
            dispatch(fetchSupportContentReset())
        }

        if (clientChatStatus == STATUS.SUCCESS) {
            // console.log("CHAT: ", clientChatResult)
            setState('local')
            dispatch(clientChatHistoryReset());
        }
        else if (clientChatStatus == STATUS.ERROR) {
            // ToastAndroid.show("Failed to load chat", ToastAndroid.SHORT);
            toast.error(clientChatErrorMessage)
            dispatch(clientChatHistoryReset());
        }

        if (sendClientChatStatus == STATUS.SUCCESS) {
            setState('Server')
            dispatch(sendClientChatReset());
            dispatch(clientChatHistoryReset());
        }
        else if (sendClientChatStatus == STATUS.ERROR) {
            // ToastAndroid.show(sendClientChatErrorMessage, ToastAndroid.SHORT);
            toast(sendClientChatErrorMessage)
            dispatch(sendClientChatReset());
        }
    })

    // FOR CHAT
    const user = { id: loginResult.mobile, username: loginResult.name, avatarUrl: loginResult.profile_photo_url  }

    useEffect(() => {
        setMessages(formatServerChatList(clientChatResult))
    },[state])

    const formatServerChatList = (chat_list) => {
        let formatted_chat = [];
        
        if (chat_list.length > 0) {
            const chats = chat_list.slice().reverse()
            chats.map((chat) => {
                let single_chat = {
                    author: { 
                        id: chat.author, 
                        username: chat.type == 1 ?  user.username : "Sava Officer", 
                        avatarUrl: chat.type == 1 ? user.avatarUrl : image.Images.userIcon 
                    },
                    id: chat.id,
                    text: chat.message,
                    timestamp: new Date(chat.created_at * 1000),
                    type: 'text',
                }
                formatted_chat.push(single_chat)
            })
        }
        return formatted_chat;
    }

    
    const handleOnSendMessage = (message) => {
        setMessages([...messages, {
                author: user,
                text: message,
                type: 'text',
                timestamp: +new Date(),
                // hasError: true,
        }])
        dispatch(sendClientChatRequest(loginResult.token, message));
    };

    const checkLoading = () => {
        if (supportDataStatus === STATUS.LOADING || clientChatStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={'Help & Support'} />
            <LoadingView loading={checkLoading()} />

            {/* select help container */}
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap="wrap"
                useFlexGap
                spacing={1}
                my={1}
            >
                <Chip
                    variant={display === "help" ? "solid" : "outlined"}
                    size="lg"
                    color={display === "help" ? "primary" : "neutral"}
                    onClick={() => setDisplay('help')}
                    startDecorator={<HelpOutline />}>
                    FAQ
                </Chip>
                <Chip
                    variant={display === "payment" ? "solid" : "outlined"}
                    size="lg"
                    color={display === "payment" ? "primary" : "neutral"}
                    onClick={() => setDisplay('payment')}
                    startDecorator={<PaidOutlined />}>
                    Payment Methods
                </Chip>
                <Chip
                    variant={display === "chat" ? "solid" : "outlined"}
                    size="lg"
                    color={display === "chat" ? "primary" : "neutral"}
                    onClick={() => setDisplay('chat')}
                    startDecorator={<SmsRounded />}>
                    Chat
                </Chip>
            </Stack>

            {/* render support list */}
            {display != "chat" && supportList.length > 0 ?
                renderSupportList(supportList)
                :
                (display === "chat" 
                    ?
                    <ChatBox
                        onSendMessage={handleOnSendMessage}
                        userId={user.id}
                        messages={messages}
                        width={'100%'}
                        height={'65vh'}
                        showTypingIndicator={true}
                        // timestampFormat={ new Date().toLocaleDateString()}
                        // activeAuthor={{ username: 'user2', id: 2, avatarUrl: null }}
                    />
                    :
                    <Card sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: '20%'
                    }}>
                        <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                        <Typography level="title-md">Fail to Fetch data from server</Typography>
                        <Typography level="body-sm" textAlign={'center'}>Please refresh to get data or check your network connect.</Typography>
                    </Card>)
            }



            {/* </Typography> */}
        </Box>
    )
}

const mapStateToProps = ({ authClient, account }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        requestSupportListResult: supportDataResult,
        requestSupportListStatus: supportDataStatus,
        requestSupportListErrorMessage: supportDataErrorMessage,
        clientChatListRequestStatus: clientChatStatus,
        clientChatListRequestResult: clientChatResult,
        clientChatListRequestErrorMessage: clientChatErrorMessage,
        sendClientChatStatus: sendClientChatStatus,
        sendClientChatErrorMessage: sendClientChatErrorMessage
    } = account

    return {
        loginResult,
        supportDataResult,
        supportDataStatus,
        supportDataErrorMessage,
        clientChatStatus,
        clientChatResult,
        clientChatErrorMessage,
        sendClientChatStatus,
        sendClientChatErrorMessage
    }
}
export default connect(mapStateToProps, {})(ClientSupportPage)