import * as React from 'react';
import Box, { BoxProps } from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import CssBaseline from '@mui/joy/CssBaseline'
import Typography from '@mui/joy/Typography';
import { ToastContainer } from 'react-toastify';
import { CssVarsProvider, Divider } from '@mui/joy';
import { useLocation, Outlet } from 'react-router-dom';
import theme from '../../utils/theme'
import BottomNavigation from './BottomNavigation';
import Layout from './Layout';
import HeaderClient from './HeaderClient';
import { motion } from 'framer-motion';

const ClientMain = () => {
    const location = useLocation()

    const animations = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exist: { opacity: 0, x: -100 },
    }

    return (
        <CssVarsProvider disableTransitionOnChange defaultMode='light' theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

                {/* Bottom navigation in mobile version */}
                <BottomNavigation />

                <Layout.Root>
                    <Layout.Header>
                        <HeaderClient />
                    </Layout.Header>

                    <Layout.Main
                        sx={{
                            px: { xs: 2, md: 20 },
                            pt: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                            },
                            pb: {
                                xs: 'calc(12px + var(--Bottom-tab-height))',
                                sm: 2,
                                md: 3
                            },
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            // height: '100dvh',
                            minHeight: '100dvh',
                            gap: 1,
                        }}>

                        {/* Page Title */}
                        {/* <Box sx={{gap: 0.5}} >
                            <Typography level='h3'>{title}</Typography>
                            <Divider component='div' sx={{ width: '30px', height: '3px', borderRadius: '20px'}} />
                        </Box> */}
                        <motion.div
                            variants={animations}
                            initial="initial"
                            animate="animate"
                            exit="exist"
                            transition={{
                                duration: 0.5
                            }} >
                            <Outlet />
                        </motion.div>
                    </Layout.Main>

                </Layout.Root>
                {/* <ToastContainer /> */}

            </Box>
        </CssVarsProvider>
    );
}

export default ClientMain