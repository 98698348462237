import { Box, Divider, Typography } from "@mui/joy";
import React from "react";

const PageTitle = ({ title }) => {
    return (
        <Box sx={{ gap: 0.5, pb: 1 }} >
            <Typography level='title-lg'>{title}</Typography>
            <Divider component='div' sx={{ width: '30px', height: '3px', borderRadius: '20px' }} />
        </Box>
    )
}

export default PageTitle