import { CLIENT } from "../../constant";

export function userRegistrationRequest(
    name,
    mobile,
    nida,
    pin,
    repin,
    region,
    district,
    inviteCode
) {
    return {
        type: CLIENT.USER_REGISTRATION_REQUEST,
        payload: {
            name,
            mobile,
            nida,
            pin,
            repin,
            region,
            district,
            inviteCode
        }
    }
}

export function userRegistrationReset() {
    return {
        type: CLIENT.USER_REGISTRATION_RESET
    }
}

export function otpRegistrationRequest(mobile, otp) {
    return {
        type: CLIENT.OTP_REGISTRATION_REQUEST,
        payload: {
            mobile,
            otp
        }
    }
}

export function otpRegistrationReset() {
    return {
        type: CLIENT.OTP_REGISTRATION_RESET
    }
}

export function resendUserOtpRequest(mobile) {
    return {
        type: CLIENT.RESEND_USER_OTP_REQUEST,
        payload: {
            mobile
        }
    }
}

export function resetOtpRequest() {
    return {
        type: CLIENT.RESEND_USER_OTP_RESET,
    }
}