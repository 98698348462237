import * as React from 'react';
import { useDispatch, connect } from 'react-redux';

import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import image from '../../constant/image';

import { NAVIGATE_TO_CLIENTLOANHISTORYPAGE, NAVIGATE_TO_CLIENTPROFILEPAGE, NAVIGATE_TO_CLIENTSUPPORTPAGE, NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE, NAVIGATE_TO_HOMECLIENTPAGE } from '../../route/types';

import { Link } from '@mui/joy';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetUserType, clientLogoutRequest } from '../../store/actions'
import { API_BASE } from '../../constant';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

const HeaderClient = ({
  loginResult
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()



  // const selector = useSelector((state) => state.account.clientDetailsResult)/
  console.log("client details", loginResult)

  const [name, setName] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [profile, setProfile] = React.useState('')

  const logOut = () => {
    dispatch(clientLogoutRequest());
    dispatch(resetUserType());
  }

  React.useEffect(() => {
    if (loginResult.name || loginResult.mobile || loginResult.profile_photo_url) {
      setName(loginResult.name)
      setMobile(loginResult.mobile)
      setProfile(loginResult.profile_photo_url)
    }
  }, [loginResult])

  const HeaderNav = ({ title, route }) => {

    return (
      <Box sx={{ margin: 'auto' }}>
        {/* <Typography level='title-sm'>{title}</Typography> */}
        <Link
          href={route}
          underline='none'
          sx={{
            color: location.pathname == route ? 'background.appcolor' : 'text.secondary',
            fontWeight: location.pathname == route ? '600' : 'normal'
          }}>
          {title}
        </Link>
        <Box sx={{
          display: location.pathname == route ? 'flex' : 'none',
          width: '80%',
          borderRadius: '50px',
          height: '3px',
          backgroundColor: 'background.appcolor',
          margin: 'auto'
        }} />
      </Box>
    )

  }
  const [open, setOpen] = React.useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      {/* Logo and App name */}
      <Link sx={{ display: 'flex', gap: 1, alignItems: 'center' }} underline='none' href={NAVIGATE_TO_HOMECLIENTPAGE}>
        <IconButton variant="soft" sx={{ backgroundColor: 'whitesmoke' }} size="sm">
          <Avatar
            src={image.Images.icon}
            sx={{ maxWidth: '32px', maxHeight: '32px', backgroundColor: 'transparent' }}
          />
        </IconButton>
        <Typography level="title-lg" sx={{
          // display: { xs: 'none', md: 'flex' },
          fontSize: '20px',
          color: 'background.appcolor'
        }}
        >SAVA</Typography>
        {/* <ColorSchemeToggle sx={{ ml: 'auto' }} /> */}
      </Link>

      {/* link navigation */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <HeaderNav route={NAVIGATE_TO_HOMECLIENTPAGE} title='Home' />
        <HeaderNav route={NAVIGATE_TO_CLIENTLOANHISTORYPAGE} title='Loan' />
        <HeaderNav route={NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE} title='Transactions' />
        {/* <HeaderNav route={null} title='About' /> */}
      </Stack>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >

        {/* Search if needed */}
        {/* <Input
          size="sm"
          variant="outlined"
          placeholder="Search anything…"
          startDecorator={<SearchRoundedIcon color="primary" />}
          endDecorator={
            <IconButton
              variant="outlined"
              color="neutral"
              sx={{ bgcolor: 'background.level1' }}
            >
              <Typography level="title-sm" textColor="text.icon">
                ⌘ K
              </Typography>
            </IconButton>
          }
          sx={{
            alignSelf: 'center',
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        /> 
         <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          sx={{ display: { xs: 'inline-flex', sm: 'none' }, alignSelf: 'center' }}
        >
          <SearchRoundedIcon />
        </IconButton> */}

        {/* change theme */}
        <ColorSchemeToggle />
        <Tooltip title="Support" variant="outlined">
          <IconButton
            id="toggle-mode"
            size="sm"
            variant="plain"
            color="neutral"
            sx={{ alignSelf: 'center' }}
            onClick={() => { navigate(NAVIGATE_TO_CLIENTSUPPORTPAGE) }}
          // href={NAVIGATE_TO_CLIENTSUPPORTPAGE}
          >
            <HelpRoundedIcon />
          </IconButton>
        </Tooltip>
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <Avatar
              src={profile}
              sx={{ maxWidth: '32px', maxHeight: '32px' }}
              // alt='j'
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem
              component="a"
              href={NAVIGATE_TO_CLIENTPROFILEPAGE}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={profile}
                  sx={{ borderRadius: '50%' }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    {name.toLowerCase() // convert uppercase name to firstuppercase
                      .split(' ')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    +{mobile}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem
              component='a'
              href='#' onClick={logOut}>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ authClient }) => {
  const {
    otpResult: loginResult
  } = authClient


  return {
    loginResult
  }
}

export default connect(mapStateToProps, {})(HeaderClient)