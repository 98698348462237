import { CLIENT, STATUS } from "../../constant";

const INITIAL_STATE = {
    guestLoginStatus: STATUS.DEFAULT,
    guestLoginErrorMessage: '',
    guestLoginResult: null,

    termsAndConditionResult: null,
    termsAndConditionStatus: STATUS.DEFAULT,
    termsAndConditionErrorMessage: '',

    forgetPasswordResult: null,
    forgetPasswordStatus: STATUS.DEFAULT,
    forgetPasswordErrorMessage: ''
}

export default (state = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case CLIENT.GUEST_LOGIN_LOADING:
            return {
                ...state,
                guestLoginStatus: STATUS.LOADING,
                guestLoginErrorMessage: '',
                guestLoginResult: null
            }
        case CLIENT.GUEST_LOGIN_SUCCESS:
            return {
                ...state,
                guestLoginStatus: STATUS.SUCCESS,
                guestLoginErrorMessage: '',
                //guestLoginResult: payload
            }  
        case CLIENT.GUEST_LOGIN_FAILURE:
            return {
                ...state,
                guestLoginStatus: STATUS.ERROR,
                guestLoginErrorMessage: payload,
                guestLoginResult: null
            }
        case CLIENT.GUEST_LOGIN_RESET:
            return {
                ...state,
                guestLoginStatus: STATUS.DEFAULT,
                guestLoginErrorMessage: '',
                guestLoginResult: null
            }
        case CLIENT.TERMS_AND_CONDITION_LOADING:
            return {
                ...state,
                termsAndConditionResult: null,
                termsAndConditionStatus: STATUS.LOADING,
                termsAndConditionErrorMessage: ''
            }
        case CLIENT.TERMS_AND_CONDITION_SUCCESS:
            return {
                ...state,
                termsAndConditionResult: payload,
                termsAndConditionStatus: STATUS.SUCCESS,
                termsAndConditionErrorMessage: ''
            }
        case CLIENT.TERMS_AND_CONDITION_FAILURE:
            return {
                ...state,
                termsAndConditionResult: null,
                termsAndConditionStatus: STATUS.ERROR,
                termsAndConditionErrorMessage: payload
            }
        case CLIENT.TERMS_AND_CONDITION_RESET:
            return {
                ...state,
                termsAndConditionResult: null,
                termsAndConditionStatus: STATUS.DEFAULT,
                termsAndConditionErrorMessage: ''
            }
        case CLIENT.FORGET_PASSWORD_LOADING: 
            return {
                ...state,
                forgetPasswordResult: null,
                forgetPasswordStatus: STATUS.LOADING,
                forgetPasswordErrorMessage: ''
            }
        case CLIENT.FORGET_PASSWORD_SUCCESS: 
            return {
                ...state,
                forgetPasswordResult: payload,
                forgetPasswordStatus: STATUS.SUCCESS,
                forgetPasswordErrorMessage: ''
            }
        case CLIENT.FORGET_PASSWORD_FAILURE: 
            return {
                ...state,
                forgetPasswordResult: null,
                forgetPasswordStatus: STATUS.ERROR,
                forgetPasswordErrorMessage: payload
            }
        case CLIENT.FORGET_PASSWORD_RESET: 
            return {
                ...state,
                forgetPasswordResult: null,
                forgetPasswordStatus: STATUS.DEFAULT,
                forgetPasswordErrorMessage: ''
            }
        default:
            return state;
    }
}