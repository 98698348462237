import { combineReducers } from "redux";

import authClientReducer from "./auth/reducers";
import accountReducer from "./account/reducers";
import guestReducer from "./guest/reducers";
import loanReducer from "./loan/reducers";
import registrationReducer from "./registration/reducers";

import userTypeReducer from './userType/reducer'
import { ACTION_RESET_APP_STATE } from '../constant';

const appReducers = combineReducers({
    // client reducers
    authClient: authClientReducer,
    account: accountReducer,
    guest: guestReducer,
    loan: loanReducer,
    registration: registrationReducer,

    user: userTypeReducer
});

const rootReducer = (state, action) => {
    //added to prevent stacking on loading possibility
    if(action.type === ACTION_RESET_APP_STATE) {
        return appReducers(undefined, action);
    }
    return appReducers(state, action);
}

export default appReducers;