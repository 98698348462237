import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
// import PublicRoute from './route/Mainroute'
import "react-toastify/dist/ReactToastify.css";

import React, { } from "react";
import { connect } from "react-redux";

import { STATUS } from '../constant';

import { ForgetPasswordPage, LoginPage, RegistrationPage } from "../page/Login";
import { ClientMain } from "../components";
import {
  ERROR_404_PAGE,
  NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE,
  NAVIGATE_TO_CLIENTLOANHISTORYPAGE,
  NAVIGATE_TO_CLIENTLOANREQUESTPAGE,
  NAVIGATE_TO_CLIENTPROFILEPAGE,
  NAVIGATE_TO_CLIENTSUPPORTPAGE,
  NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE,
  NAVIGATE_TO_FORGETPASSWORDPAGE,
  NAVIGATE_TO_HOMECLIENTPAGE,
  NAVIGATE_TO_LOANDESCRIPTIONPAGE,
  NAVIGATE_TO_LOANUNDERWRITINGPAGE,
  NAVIGATE_TO_LOGINPAGE,
  NAVIGATE_TO_REGISTRATIONPAGE,
  NAVIGATE_TO_REPAYMENTPLANPAGE,
  NAVIGATE_TO_TERMSANDCONDITIONPAGE,
} from "./types";

import {
  ClientLoanCompletePage,
  ClientLoanHistoryPage,
  ClientLoanRequestPage,
  ClientProfilePage,
  ClientSupportPage,
  ClientTransactionHistoryPage,
  HomeClient,
  LoanDescriptionPage,
  LoanUnderWritingPage,
  RepaymentPlanPage,
  TermsAndConditions,
  Error404Page
} from "../page";

// FUNCTION TO DIRECT ONLY AUTH USER TO THEIR PAGES
const ProtectRoute = ({ status }) => {
  return status === STATUS.SUCCESS ? (
    <Outlet />
  ) : (
    <Navigate to={NAVIGATE_TO_LOGINPAGE} />
  );
};

// MAIN TO RENDER ROUTES AND PAGES
const RoutesContainer = ({
  loginStatus
}) => {
  const location = useLocation();

  return (
    <Routes key={location.pathname} location={location}>
      {/* <TransitionGro */}
      {/* FOR SAVA AMBASSADOR PAGES */}
      <Route path={NAVIGATE_TO_LOGINPAGE} element={<LoginPage />} />
      <Route path={NAVIGATE_TO_REGISTRATIONPAGE} element={<RegistrationPage />} />
      <Route path={NAVIGATE_TO_FORGETPASSWORDPAGE} element={<ForgetPasswordPage />} />


      {/* FOR SAVA CLIENT PAGES */}
      <Route path="/" element={<ProtectRoute status={loginStatus} />}>
        <Route path="/" element={<ClientMain />}>
          <Route path={NAVIGATE_TO_HOMECLIENTPAGE}
            element={<HomeClient />}
          />

          <Route
            path={NAVIGATE_TO_CLIENTLOANREQUESTPAGE}
            element={<ClientLoanRequestPage />}
          />
          <Route
            path={NAVIGATE_TO_LOANUNDERWRITINGPAGE}
            element={<LoanUnderWritingPage />}
          />
          <Route
            path={NAVIGATE_TO_REPAYMENTPLANPAGE}
            element={<RepaymentPlanPage />}
          />
          <Route
            path={NAVIGATE_TO_CLIENTLOANHISTORYPAGE}
            element={<ClientLoanHistoryPage />}
          />
          <Route
            path={NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE}
            element={<ClientLoanCompletePage />}
          />

          <Route
            path={NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE}
            element={<ClientTransactionHistoryPage />}
          />

          <Route
            path={NAVIGATE_TO_CLIENTPROFILEPAGE}
            element={<ClientProfilePage />}
          />
          <Route
            path={NAVIGATE_TO_CLIENTSUPPORTPAGE}
            element={<ClientSupportPage />}
          />
          <Route
            path={NAVIGATE_TO_LOANDESCRIPTIONPAGE}
            element={<LoanDescriptionPage />}
          />
        </Route>
      </Route>

      <Route
            path={NAVIGATE_TO_TERMSANDCONDITIONPAGE}
            element={<TermsAndConditions />}
          />

      <Route path={ERROR_404_PAGE} element={<Error404Page />} />
    </Routes>
  );
};

export default RoutesContainer;
