
import { extendTheme } from '@mui/joy/styles';


declare module '@mui/joy/styles' {
  interface PaletteBackgroundOverrides {
    appcolor: true;
  }
}

const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": {
        "primary": {
          "50": "#edf5fd",
          "100": "#cfcddc",
          "200": "#9f9bb9",
          "300": "#6f6a96",
          "400": "#3f3873",
          "500": "#0f0650",
          "600": "#0c0540",
          "700": "#090430",
          "800": "#060220",
          "900": "#030110"
        },
        "background": {
          "appcolor": "#0F0650"
        }
      }
    },
    "dark": {
      "palette": {
        "primary": {
          "900": "#edf5fd",
          "800": "#cfcddc",
          "700": "#9f9bb9",
          "600": "#6f6a96",
          "500": "#3f3873",
          "400": "#0f0650",
          "300": "#0c0540",
          "200": "#090430",
          "100": "#060220",
          "50": "#030110"
        },
        "background": {
          "appcolor": "#EDE5ED",
        }
      }
    }
  }
})


export default theme;