import React, { useEffect } from "react";
import { Box, Typography } from "@mui/joy";
import { toast } from 'react-toastify';
import { ClientMain, PageTitle } from "../../../components";
import { useLocation, useNavigation } from "react-router-dom";


const LoanDescriptionPage = () => {

    const { state } = useLocation()

    console.log("Contents: ", state.content)
    const description = state.content.description
    const paragraphs = description.split("\n")
    // description.toTime()
    return (
        <Box  
        sx={{
            boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.3)',
            backgroundColor: 'background.level1',
            p: 1
        }}>
            
                <PageTitle title={'Loan Description'} />
                {/* <ToastContainer /> */}
                <Typography level="body-md" fontSize={15}>
                    {paragraphs.map((paragraph, index) => (
                        <p key={index} style={{ margin: 0, padding: 2, }}>{paragraph}</p>
                    ))}
                </Typography>
        </Box>

    )
}

export default LoanDescriptionPage