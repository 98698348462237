import { CHANGE_USER_PIN_URL, CLIENT_OTP_URL, LOGIN_CLIENT_URL as LOGIN_URL } from "../../constant"
import { guestRequest,  resourceRequest } from "../calls"
// import VersionCheck from "react-native-version-check";

function doUserLogin(username, password, email, fcm_token) {
    // let app_version = VersionCheck.getCurrentVersion();
    let app_version = '1.1.9'
    const data = {
        username,
        password,
        email,
        'version': app_version,
        'push_device_token': fcm_token
    }

    //return request({ url: LOGIN_URL, method: 'POST', data }); 
    return guestRequest( LOGIN_URL,data ); 
}

export function doClientVerifyOTP(username, OTP){
    let app_version = '1.1.9'
    const data = {
        username,
        OTP,
        // 'version': app_version,
        'push_device_token': ""
    }
    return guestRequest(CLIENT_OTP_URL, data)
}

function doChangeUserPin(token, old_pass, new_pass, confirm_pass) {
    const data = {
        old_pass,
        new_pass,
        confirm_pass
    }
    return resourceRequest(token, CHANGE_USER_PIN_URL, data);
}

export {
    doUserLogin,
    doChangeUserPin
}