export default {
    Images: {
        icon: require("../assets/image/Icon.png"),
        logo: require("../assets/image/SAVA.png"),
        logoWhite: require("../assets/image/SAVA-WHITE.png"),
        userIcon: require("../assets/image/userIcon.png"),
        userIcon2: require("../assets/image/userIcon2.jpg"),
        backgroung: require("../assets/image/colorkit.png"),
        backgroung2: require("../assets/image/colorkit2.png"),
        background_transparent: require("../assets/image/background-transparent.png")
    }
}