/************* NAME OF ROUTES ********************* */
/* -------------- AMBASSADOR ROUTES ----------------- */
// Login
export const NAVIGATE_TO_LOGINPAGE = '/login';
export const NAVIGATE_TO_FORGETPASSWORDPAGE = '/forget-password';
export const NAVIGATE_TO_REGISTRATIONPAGE = '/registration'


/* ------------- SAVA CLIENT ROUTES ---------------- */
// Home
export const NAVIGATE_TO_HOMECLIENTPAGE = '/'

// Loan
export const NAVIGATE_TO_CLIENTLOANREQUESTPAGE = '/loan-request';
export const NAVIGATE_TO_CLIENTLOANHISTORYPAGE = '/loan-history';
export const NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE = '/loan-complete';
export const NAVIGATE_TO_LOANUNDERWRITINGPAGE = '/loan-underwritings';
export const NAVIGATE_TO_REPAYMENTPLANPAGE = '/repayment-plan';

//Transactions
export const NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE = '/transactions';

//Support
export const NAVIGATE_TO_CLIENTSUPPORTPAGE = '/support';
export const NAVIGATE_TO_LOANDESCRIPTIONPAGE = '/loan-description';
export const NAVIGATE_TO_TERMSANDCONDITIONPAGE = '/terms-and-conditions';

//Profile
export const NAVIGATE_TO_CLIENTPROFILEPAGE = '/profile';

// Error Pages
export const ERROR_404_PAGE ='*'