import { ACTION_RESET_PUSH_COUNT, ACTION_UPDATE_PUSH_COUNT, CLIENT } from "../../constant";

export function clientLogoutRequest() {
    return {
        type: CLIENT.CLIENT_OTP_RESET
    }
}

export function clientLoginRequest(username, password, email, fcm_token) {
    return {
        type: CLIENT.USER_LOGIN_REQUEST,
        payload: {
            username,
            password,
            email,
            fcm_token
        }
    }
}

export function clientLoginReset() {
    return {
        type: CLIENT.USER_LOGIN_RESET
    }
}

export function clientOTPRequest(username, OTP) {
    return {
        type: CLIENT.CLIENT_OTP_REQUEST,
        payload: {
            username,
            OTP
        }
    }
}

export function clientOTPReset() {
    return {
        type: CLIENT.CLIENT_OTP_RESET
    }
}

export function changeUserPinRequest(token, old_pass, new_pass, confirm_pass) {
    return {
        type: CLIENT.CHANGE_USER_PIN_REQUEST,
        payload: {
            token,
            old_pass,
            new_pass,
            confirm_pass
        }
    }
}

export function changeUserPinReset() {
    return {
        type: CLIENT.CHANGE_USER_PIN_RESET
    }
}

export function updateFirebasePushCount() {
    return {
        type: ACTION_UPDATE_PUSH_COUNT
    }
}

export function resetFirebaseCount() {
    return {
        type: ACTION_RESET_PUSH_COUNT
    }
} 