import { CLIENT, STATUS } from "../../constant";

const INITIAL_STATE = {
    userRegistrationStatus: STATUS.DEFAULT,
    userRegistrationErrorMsg: '',
    userRegistrationResult: null,

    otpRegistrationStatus: STATUS.DEFAULT,
    otpRegistrationErrorMsg: '',
    otpRegistrationResult: null,

    resendOtpStatus: STATUS.DEFAULT,
    resendOtpResult: null,
    resendOtpErrorMessage: ''
}

export default (state = INITIAL_STATE, {type, payload}) => {
    switch (type) {
        case CLIENT.USER_REGISTRATION_LOADING:
            return {
                ...state,
                userRegistrationStatus: STATUS.LOADING,
                userRegistrationErrorMsg: '',
                userRegistrationResult: null
            }
        case CLIENT.USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                userRegistrationStatus: STATUS.SUCCESS,
                userRegistrationErrorMsg: '',
                userRegistrationResult: payload
            }
        case CLIENT.USER_REGISTRATION_FAILURE:
            return {
                ...state,
                userRegistrationStatus: STATUS.ERROR,
                userRegistrationErrorMsg: payload,
                userRegistrationResult: null
            }
        case CLIENT.USER_REGISTRATION_RESET:
            return {
                ...state,
                userRegistrationStatus: STATUS.DEFAULT,
                userRegistrationErrorMsg: '',
                userRegistrationResult: null
            }
        case CLIENT.OTP_REGISTRATION_LOADING:
            return {
                ...state,
                otpRegistrationStatus: STATUS.LOADING,
                otpRegistrationErrorMsg: '',
                otpRegistrationResult: null
            }
        case CLIENT.OTP_REGISTRATION_SUCCESS: 
            return {
                ...state,
                otpRegistrationStatus: STATUS.SUCCESS,
                otpRegistrationErrorMsg: '',
                otpRegistrationResult: payload
            }
        case CLIENT.OTP_REGISTRATION_FAILURE: 
            return {
                ...state,
                otpRegistrationStatus: STATUS.ERROR,
                otpRegistrationErrorMsg: payload,
                otpRegistrationResult: null
            }
        case CLIENT.OTP_REGISTRATION_RESET: 
            return {
                ...state,
                otpRegistrationStatus: STATUS.DEFAULT,
                otpRegistrationErrorMsg: '',
                otpRegistrationResult: null
            }
        case CLIENT.RESEND_USER_OTP_LOADING:
            return {
                ...state,
                resendOtpStatus: STATUS.LOADING,
                resendOtpResult: null,
                resendOtpErrorMessage: ''
            }
        case CLIENT.RESEND_USER_OTP_SUCCESS:
            return {
                ...state,
                resendOtpStatus: STATUS.SUCCESS,
                resendOtpResult: payload,
                resendOtpErrorMessage: ''
            }
        case CLIENT.RESEND_USER_OTP_FAILURE:
            return {
                ...state,
                resendOtpStatus: STATUS.ERROR,
                resendOtpResult: null,
                resendOtpErrorMessage: payload
            }
        case CLIENT.RESEND_USER_OTP_RESET:
            return {
                ...state,
                resendOtpStatus: STATUS.DEFAULT,
                resendOtpResult: null,
                resendOtpErrorMessage: ''
            }
        default:
            return state;
    }

}