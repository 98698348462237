import { ACTION_RESET_PUSH_COUNT, ACTION_UPDATE_PUSH_COUNT, CLIENT, STATUS } from "../../constant";

const INITIAL_STATE = {
    // account information
    userLoginStatus: STATUS.DEFAULT,
    userLoginErrorMessage: '',
    userLoginResult: null,

    //change user password state
    changeUserPinResult: null,
    changeUserPinStatus: STATUS.DEFAULT,
    changeUserPinErrorMessage: '',

    // otp
    otpStatus: STATUS.DEFAULT,
    otpResult: null,
    otpErrorMessage: ""
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        // LOGIN
        case CLIENT.USER_LOGIN_LOADING:
            return {
                ...state,
                userLoginStatus: STATUS.LOADING,
                //userToken: payload
            }
        case CLIENT.USER_LOGIN_SUCCESS:
            return {
                ...state,
                userLoginStatus: STATUS.SUCCESS,
                userLoginResult: payload,
                userLoginErrorMessage: ''
            }
        case CLIENT.USER_LOGIN_FAILURE:
            return {
                ...state,
                userLoginStatus: STATUS.ERROR,
                userLoginErrorMessage: payload
            }
        case CLIENT.USER_LOGIN_RESET:
            return {
                ...state,
                userLoginStatus: STATUS.DEFAULT,
                userLoginResult: null,
                userLoginErrorMessage: '',

            }

        //OTP
        // OTP
        case CLIENT.CLIENT_OTP_LOADING:
            return {
                ...state,
                otpStatus: STATUS.LOADING
            }
        case CLIENT.CLIENT_OTP_SUCCESS:
            return {
                ...state,
                otpStatus: STATUS.SUCCESS,
                otpResult: payload,
                otpErrorMessage: ""
            }
        case CLIENT.CLIENT_OTP_FAILURE:
            return {
                ...state,
                otpStatus: STATUS.ERROR,
                otpResult: null,
                otpErrorMessage: payload
            }
        case CLIENT.CLIENT_OTP_RESET:
            return {
                ...state,
                otpStatus: STATUS.DEFAULT,
                otpResult: null,
                otpErrorMessage: ""
            }

        // CHANGE PASSWORD
        case CLIENT.CHANGE_USER_PIN_LOADING:
            return {
                ...state,
                changeUserPinResult: null,
                changeUserPinStatus: STATUS.LOADING,
                changeUserPinErrorMessage: ''
            }
        case CLIENT.CHANGE_USER_PIN_SUCCESS:
            return {
                ...state,
                changeUserPinResult: payload,
                changeUserPinStatus: STATUS.SUCCESS,
                changeUserPinErrorMessage: ''
            }
        case CLIENT.CHANGE_USER_PIN_FAILURE:
            return {
                ...state,
                changeUserPinResult: null,
                changeUserPinStatus: STATUS.ERROR,
                changeUserPinErrorMessage: payload
            }
        case CLIENT.CHANGE_USER_PIN_RESET:
            return {
                ...state,
                changeUserPinResult: null,
                changeUserPinStatus: STATUS.DEFAULT,
                changeUserPinErrorMessage: ''
            }
        case ACTION_UPDATE_PUSH_COUNT:
            return {
                ...state,
                firebasePushCount: 1,
            }
        case ACTION_RESET_PUSH_COUNT:
            return {
                ...state,
                firebasePushCount: 0,
            }
        default:
            return state;
    }
}