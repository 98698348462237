import React, { useEffect, useState } from "react";
import { Box, Typography, CardContent, Card, CardActions, Button, ButtonGroup, StepIndicator, Step, Stepper, stepClasses, stepIndicatorClasses, Chip, Skeleton, LinearProgress, Accordion, AccordionSummary, AccordionDetails, Sheet, Table, Modal, ModalDialog, DialogTitle, ModalClose, DialogContent, FormControl, FormLabel, Input, CircularProgress, Divider, Link as LinkMoy } from "@mui/joy";
import { toast, ToastContainer } from 'react-toastify';
import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { NAVIGATE_TO_CLIENTLOANHISTORYPAGE, NAVIGATE_TO_CLIENTLOANREQUESTPAGE } from "../../../route/types";
import WalletRounded from "@mui/icons-material/WalletRounded";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";

import {
    activeLoanDetailRequest, activeLoanDetailReset,
    paymentMethodReset,
    initiatePushPaymentReset,
    loanHistoryReset,
    pendingLoanDetailRequest, pendingLoanDetailReset,
    initiatePushPaymentRequest,
    clientDetailsReset
} from "../../../store/actions"
// import Card from '@mui/joy/Card';
import { HomeRounded, CreditScore, HourglassBottomRounded, CheckCircleOutlineRounded, PendingRounded, PendingOutlined, DescriptionOutlined, CalendarMonth, ExpandMore, ExpandLess, WarningRounded } from "@mui/icons-material";
import { connect, useDispatch } from "react-redux";
import { API_BASE, STATUS } from "../../../constant";
import { Link, useNavigate } from "react-router-dom";

import { formatDate, thousandSeparator } from "../../../utils";
import { useMediaQuery, } from "@mui/material";
import { useTheme } from '@mui/material/styles';



/* ---------------- LOANS CATEGORIES RENDER CONTENTS ----------------- */
const LoanCategories = ({ actionsProps, activeProps, pendingProps, name }) => {
    // let loanCategory = 'No_Loan'
    let loanCategory = actionsProps.loanStatus

    const schedules = activeProps.loanSchedule
    if (loanCategory == 'No_Loan') {
        return (
            <Card
                variant="soft"
                sx={{
                    justifyContent: 'center',
                    // flexDirection: { xs: 'column', md: 'row' },
                    flexDirection: 'column',
                    columnGap: 6,
                    width: {xs: '100%', md: 'auto'},
                    alignItems: 'center',
                    px: { xs: 3, md: 20 },
                    py: { xs: 2, md: 8 },
                    background: 'linear-gradient(to bottom, #554E87, #0F0650)',
                    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)'
                }} >
                <CardContent
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center'
                        // gap: 0
                    }}>
                    <Typography
                        level="body-lg"
                        sx={{
                            color: 'white'
                        }}>You can get Loan up to</Typography>
                    <Typography
                        level="h2"
                        sx={{
                            color: 'white'
                        }}>Tsh. 300,000.00</Typography>
                    {/* <Chip
                        variant="outlined"
                        size="sm"
                        sx={{  backgroundColor: 'transparent', color: 'white' }}
                    >
                        Get fast loan
                    </Chip> */}
                </CardContent>

                <CardActions sx={{ flexDirection: 'column' }}>
                    <Link to={NAVIGATE_TO_CLIENTLOANREQUESTPAGE}>
                        <Button variant="soft" onClick={null}
                            sx={{
                                backgroundColor: 'green',
                                color: 'white',
                                borderRadius: 20,
                                px: 5
                            }}>
                            Apply Loan
                        </Button>
                    </Link>
                    <LinkMoy
                        href={NAVIGATE_TO_CLIENTLOANHISTORYPAGE}
                        underline="none"
                        sx={{
                            color: 'gold',
                            textDecorationLine: 'none',
                            fontWeight: 'bold',
                            fontSize: 14
                        }}>
                        View Previous Loans
                    </LinkMoy>
                </CardActions>
            </Card >
        )
    }
    else if (loanCategory == 'Pending') {
        return (
            <Card
                variant="plain"
                sx={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    columnGap: 6,
                    alignItems: 'center',
                    px: { xs: 3, md: 10 },
                    py: { xs: 2, md: 8 },
                    width: {xs: '100%', md: 'auto'},
                    background: 'linear-gradient(to bottom, #554E87, #0F0650)',
                    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)'
                }} >
                <CardContent
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        // gap: 0
                        width: '100%'
                    }}>
                    <Typography
                        level="body-lg"
                        sx={{
                            color: 'white',
                            textAlign: 'center'
                        }}>Your Loan Request</Typography>

                    <Typography
                        level="h2"
                        sx={{
                            color: 'white',
                            textAlign: 'center',
                        }}>Tsh. {thousandSeparator(pendingProps.loanAmount)}</Typography>
                    <Chip
                        variant="soft"
                        size="md"
                        startDecorator={<HourglassBottomRounded />}
                        sx={{ backgroundColor: 'gold', color: '#0f0650', alignSelf: 'center', margin: 0.2 }}
                    >
                        Pending | Wait for approval
                    </Chip>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: { xs: 2, md: 4 },
                            gap: { xs: 0, md: 20 },
                            display: 'flex',
                            justifyContent: "space-between",
                        }}>
                        <Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Date:</b> <br />{formatDate(pendingProps.requestDate)}</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Loan Duration:</b> <br />{pendingProps.loanTenure} Months</Typography>
                        </Box>

                        <Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white',
                                    textAlign: 'right'
                                }}><b>Ambassador Name:</b> <br />{pendingProps.ambassador_name}</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white',
                                    textAlign: 'right'
                                }}><b>Ambassador No:</b> <br />{pendingProps.ambassador} </Typography>

                        </Box>
                    </Box>
                    {/* <Button variant="solid" onClick={null} sx={{ mt: 1 }}>
                            View Details
                        </Button> */}

                </CardContent>
            </Card>
        )

    }
    else if (loanCategory == 'Active') {
        return (
            <Card
                variant="soft"
                sx={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    columnGap: 6,
                    alignItems: 'center',
                    width: {xs: '100%', md: 'auto'},
                    px: { xs: 3, md: 10 },
                    py: { xs: 2, md: 4 },
                    background: 'linear-gradient(to bottom, #554E87, #0F0650)',
                    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)'
                }} >
                <CardContent
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        // gap: 0
                        width: '100%'
                    }}>
                    <Typography
                        level="body-lg"
                        sx={{
                            color: 'white',
                            textAlign: 'center',
                        }}>Remain Amount</Typography>

                    {/* remain loan amount */}
                    <Typography
                        level="h2"
                        sx={{
                            color: 'white',
                            textAlign: 'center'
                        }}>Tsh. {thousandSeparator(activeProps.remainDebt)}</Typography>

                    {/* progress status */}
                    <Box
                        sx={{
                            width: { xs: '90%', md: '90%' },
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignSelf: 'center'
                        }}>
                        <Typography
                            level="body-sm"
                            sx={{
                                color: 'white',
                            }}>Loan Status</Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                color: 'white',
                            }}>{parseInt(activeProps.percent)} %</Typography>
                    </Box>
                    <LinearProgress
                        value={parseInt(activeProps.percent) || 0}
                        determinate
                        color="warning"
                        variant="solid"
                        sx={{
                            alignSelf: 'center',
                            width: { xs: '90%', md: '90%' },
                            '--LinearProgress-radius': '20px',
                            '--LinearProgress-thickness': '8px',
                            // '--LinearProgress-'
                        }} />

                    {/* loan details */}
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: { xs: 2, md: 4 },
                            gap: { xs: 2, md: 45 },
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'flex-start'
                        }}>
                        <Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Total Amount:</b> <br />Tsh. {thousandSeparator(parseInt(activeProps.paidAmount) + parseInt(activeProps.remainDebt))}</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Paid Amount:</b> <br />Tsh. {thousandSeparator(activeProps.paidAmount)}</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Due Date: </b><br/>{activeProps.deadlineDate}</Typography>
                        </Box>

                        <Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Loan Duration:</b> <br />{activeProps.duration} Months</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>Approve Date:</b> <br />{activeProps.formattedApprovedDate}</Typography>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions sx={{
                    // alignSelf: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: { xs: 'center', md: 'space-between' }
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            // justifyContent: 'space-between',
                        }}>
                        <Button variant="soft" size="sm" onClick={actionsProps.setOpenSchedule} startDecorator={actionsProps.openSchedule ? <ExpandLess /> : <ExpandMore />} sx={{ mt: 1 }}>
                            Payment Schedule
                        </Button>
                        <Link to={activeProps.contract} target="_blank" rel="noopener noreferrer">
                            <Button variant="soft" size="sm" startDecorator={<DescriptionOutlined />} sx={{ mt: 1 }}>
                                View Contract
                            </Button>
                        </Link>
                    </Box>

                    <Button 
                    variant="solid" 
                    onClick={actionsProps.openPushModal} 
                    size="md"
                    sx={{ 
                        mt: 1,
                        width: {xs: '100%', md: 'auto'},
                        backgroundColor: 'green',
                                color: 'white',
                                borderRadius: 20,
                                px: 5,
                                py: {xs: 1.2},
                                fontSize: 16,
                         }}>
                        Push Payment
                    </Button>
                </CardActions>

                {actionsProps.openSchedule && loanSchedules(schedules)}
            </Card>
        )

    }
    else {
        return (
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                top: '20%'
            }}>
                <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                <Typography level="title-md">Failed to fetch data</Typography>
                <Typography level="body-sm" textAlign={'center'}>Please check your network connection and refresh page.</Typography>
            </Card>
        )
    }

}
// STEPS TO GET LOAN
const renderSteps = (isMobile) => {

    const item = [{
        id: 1,
        icon: CreditScore,
        title: 'Apply Loan'
    },
    {
        id: 2,
        icon: HourglassBottomRounded,
        title: 'Ambassador Approve',
    },
    {
        id: 3,
        icon: CheckCircleOutlineRounded,
        title: 'Receive Loan'
    }]

    return (
        <Box sx={{
            pt: 1,
            width: {xs: '100%', md: 'auto'}
        }}>
            <Typography level="title-md" sx={{ color: { md: 'whitesmoke' }, fontWeight: 'bold' }}>Steps to Get Loan</Typography>
            <Stepper
                size="lg"
                orientation={isMobile ? 'horizontal' : 'vertical'}
                sx={{
                    width: '100%',
                    pt: 1,
                    '--StepIndicator-size': '3rem',
                    '--Stepper-verticalGap': '2.5rem',
                    // '--StepIndicator-size': '2.5rem',
                    // '--Step-gap': '1rem',
                    '--Step-connectorInset': '0.5rem',
                    '--Step-connectorRadius': '1rem',
                    '--Step-connectorThickness': '2px',
                    // '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderWidth: 4,
                        // '&::after': { bgcolor: 'white' },
                    },
                    [`& .${stepClasses.root}::after`]: {
                        // height: 14,
                        backgroundColor: { xs: "text.secondary", md: 'white' },
                    },
                    [`& .${stepClasses.active}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor',
                        },
                    },
                }}>
                {item.map((item) => (
                    <Step
                        key={item.id}
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        // orientation="vertical"
                        active
                        indicator={
                            <StepIndicator variant="soft">
                                <item.icon />
                            </StepIndicator>
                        }
                    >
                        <Typography
                            sx={{
                                // textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.75rem',
                                letterSpacing: '0.5px',
                                textAlign: { xs: 'center', md: "left" },
                                color: { md: 'whitesmoke' }
                            }}
                        >
                            {item.title}
                        </Typography>
                    </Step>
                ))}
            </Stepper >
        </Box>
    )
}


// LOAN SCHEDULES
const loanSchedules = (loanSchedules) => {

    const status = (st) => {
        if (st == 3 || st == 4) {
            return "Paid"
        }
        else if (st == 5 || st == 6 || st == 7) {
            return "Delayed"
        } else {
            return "Active"
        }
    }
    return (
        <Sheet
            variant="soft"
            sx={{
                p: 1,
                pl: 6,
                boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)",
            }}>
            <Typography level="body-lg" component="div"></Typography>
            <Table
                borderAxis="bothBetween"
                size="sm"
                aria-label="purcha"
                sx={{
                    "& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)":
                        {},
                    "--TableCell-paddingX": "0.5rem",
                }}>
                <thead>
                    <tr>
                        <th>Pay Date</th>
                        <th>Amount</th>
                        <th>Remain</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {loanSchedules.map((sch) => (
                        <tr key={sch.id}>
                            <td>{formatDate(sch.due_date)}</td>
                            <td>{sch.installment_amount}</td>
                            <td>{sch.remain}</td>
                            <td>{status(sch.status)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    )
}

/* ------------------ MAIN FUNCTION -------------------- */
const HomeClient = ({
    loginResult,
    loanHistoryResult,
    loanHistoryStatus,
    loanHistoryErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,
    clientDetailsErrorMessage,

    pendingLoanDetailStatus,
    pendingLoanDetailResult,
    pendingLoanDetailErrorMessage,

    loanDetailRequestStatus,
    loanDetailResult,
    loanDetailErrorMessage,
    paymentMethodResult,
    paymentMethodStatus,
    paymentMethodErrorMessage,
    initiatePushPaymentStatus,
    initiatePushPaymentResult,
    initiatePushPaymentErrorMessage,
    transactionHistoryRequestStatus,
    transactionHistoryRequestResult,
}) => {

    const [openSchedule, setOpenSchedule] = useState(false)


    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // for active
    const [debt, setDept] = useState(0);
    const [remainDebt, setRemainDebt] = useState(0);
    const [duration, setDuration] = useState(0);
    const [approvedDate, setApprovedDate] = useState('');
    const [formattedApprovedDate, setFormatedApprovedDate] = useState('');
    const [deadlineDate, setDeadlineDate] = useState('');
    const [paidAmount, setPaidAmount] = useState(0);
    const [percent, setPercent] = useState(0);
    const [isLate, setIsLate] = useState(false);
    const [loanSchedule, setLoanSchedule] = useState([]);
    const [contract, setContract] = useState("")

    // for pending
    const [loanAmounnt, setLoanAmount] = useState(0);
    const [loanTenure, setLoanTenure] = useState(0);
    const [requestDate, setRequestDate] = useState("");
    const [ambassador, setAmbassador] = useState("");
    const [ambassadorName, setAmbassadorName] = useState("");

    // for client details and status
    const [name, setName] = useState('');
    const [loanStatus, setLoanStatus] = useState("");

    const activeProps = {
        debt: debt,
        remainDebt: remainDebt,
        duration: duration,
        approvedDate: approvedDate,
        formattedApprovedDate: formattedApprovedDate,
        deadlineDate: deadlineDate,
        paidAmount: paidAmount,
        percent: percent,
        isLate: isLate,
        loanSchedule: loanSchedule,
        contract: contract
    }

    const pendingProps = {
        loanAmount: loanAmounnt,
        loanTenure: loanTenure,
        requestDate: requestDate,
        ambassador: ambassador,
        ambassador_name: ambassadorName
    }

    useEffect(() => {
        // client details
        if (clientDetailsStatus === STATUS.SUCCESS) {
            setLoanStatus(clientDetailsResult.loan_status)
            if (clientDetailsResult.loan_status === "Active") {
                dispatch(activeLoanDetailRequest(loginResult.token));
            } else if (clientDetailsResult.loan_status === "Pending") {
                dispatch(pendingLoanDetailRequest(loginResult.token));
            }
        }
        else if (clientDetailsStatus === STATUS.ERROR) {
            // dispatch(clientDetailsReset())
            toast.error(clientDetailsErrorMessage)
        }
    }, [clientDetailsStatus])

    useEffect(() => {
        // active loans
        if (loanDetailRequestStatus === STATUS.SUCCESS) {
            // console.log("Fetched loan Data", loanDetailResult)
            setDuration(loanDetailResult.duration);
            setApprovedDate(loanDetailResult.approved_date);
            setLoanSchedule(loanDetailResult.repayment_schedule);
            setContract(loanDetailResult.contract);

            if (parseInt(loanDetailResult.amount)) {
                setDept(parseInt(loanDetailResult.amount));
            }

            if (parseInt(loanDetailResult.paid_amount)) {
                setPaidAmount(parseInt(loanDetailResult.paid_amount));
            }

            if (loanDetailResult.remain_amount) {
                setRemainDebt(parseInt(loanDetailResult.remain_amount))
            }


            dispatch(activeLoanDetailReset());
        }
        else if (loanDetailRequestStatus == STATUS.ERROR) {
            // toast.error(loanDetailErrorMessage)
            dispatch(activeLoanDetailReset());
        }

        // payment methode
        if (paymentMethodStatus === STATUS.SUCCESS) {
            if (paymentMethodResult) {
                // navigate(NAVIGATION_PAYMENT_METHOD_SCREEN, {
                //     support_list: paymentMethodResult.content
                // });
            } else {
                toast.error('Failed to fetch payment data')
            }

            dispatch(paymentMethodReset());
        }
        else if (paymentMethodStatus === STATUS.ERROR) {
            toast.error(paymentMethodErrorMessage)
            dispatch(paymentMethodReset());
        }

        // pending loans
        if (pendingLoanDetailStatus === STATUS.SUCCESS) {
            if (pendingLoanDetailResult) {
                setLoanAmount(pendingLoanDetailResult.amount);
                setLoanTenure(pendingLoanDetailResult.duration);
                setAmbassador(pendingLoanDetailResult.ambassador_code);
                setAmbassadorName(pendingLoanDetailResult.ambassador_name)
                setRequestDate(pendingLoanDetailResult.initiated_date);
                dispatch(pendingLoanDetailReset())
            }
        }
        else if (pendingLoanDetailStatus === STATUS.ERROR) {
            // toast.error(pendingLoanDetailErrorMessage)
            dispatch(pendingLoanDetailReset())
        }

        // payment push
        if (initiatePushPaymentStatus === STATUS.SUCCESS) {
            setLoadPush(false)
            setPushModal(false)
            toast.success('Request Received')
            dispatch(initiatePushPaymentReset());
        }
        else if (initiatePushPaymentStatus === STATUS.ERROR) {
            setLoadPush(false)
            toast.success(initiatePushPaymentErrorMessage)
            dispatch(initiatePushPaymentReset());
        }

    });

    // calculate debt percentage
    useEffect(() => {
        //calculate percentage
        var _totalLoan = debt + remainDebt;

        var _paidAmount = paidAmount ? paidAmount : 0

        var _paidPercent = (_paidAmount / _totalLoan) * 100;
        if (_paidPercent > 100) {
            setPercent(100);
        }
        setPercent(_paidPercent);

    }, [paidAmount, debt]);

    // set and check deadline date
    useEffect(() => {
        if (approvedDate != '') {
            //as all current duration is month so we only add month
            var app_date = new Date(approvedDate);
            //format approve Date
            setFormatedApprovedDate(app_date.getDate() + "/" + (app_date.getMonth() + 1) + "/" + app_date.getFullYear());
            let _end_date = new Date(app_date);
            _end_date.setMonth(_end_date.getMonth() + duration);
            var deadline = _end_date.getDate() + "/" + (_end_date.getMonth() + 1) + "/" + _end_date.getFullYear();

            setDeadlineDate(deadline);

            var _today = new Date();
            if (_today > _end_date) {
                setIsLate(true)
                //console.log("Late loan")
            } else {
                setIsLate(false);
                //console.log("loan is not due yet")
            }
        }
    }, [approvedDate])

    // to set name
    useEffect(() => {
        const [first, last] = loginResult.name.split(' ');
        setName(first)
    }, [name])

    const goToLoanRequestPage = () => {
        navigate(NAVIGATE_TO_CLIENTLOANREQUESTPAGE)
    }

    // for pushmodal
    const [pushModal, setPushModal] = useState(false)
    const [inputAmount, setInputAmount] = useState("");
    const [wholeAmount, setWholeAmount] = useState(false)
    const [loadPush, setLoadPush] = useState(false)

    useEffect(() => {
        if (wholeAmount) {
            setInputAmount(remainDebt.toString())
        } else {
            setInputAmount(inputAmount)
        }
    }, [wholeAmount])

    const startPush = () => {
        if (inputAmount) {
            setLoadPush(true)
            dispatch(initiatePushPaymentRequest(loginResult.token, inputAmount))
        } else {
            toast.error("Please enter amount to pay")
        }
    }

    // check loading status
    const checkLoading = () => {
        if (clientDetailsStatus === STATUS.LOADING) {
            return true
        }
        else if (loanStatus === 'Active' && loanDetailRequestStatus === STATUS.LOADING) {
            return true
        }
        else if (loanStatus === "Pending" && pendingLoanDetailStatus === STATUS.LOADING) {
            return true

        } else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={"Welcome, " + name} />
            <LoadingView loading={checkLoading()} />
            {/* <ToastContainer /> */}
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: 2, md: 5 },
            }}>
                <LoanCategories
                    actionsProps={{
                        openSchedule: openSchedule,
                        setOpenSchedule: () => setOpenSchedule(!openSchedule),
                        goToLoanRequestPage: goToLoanRequestPage,
                        openPushModal: () => setPushModal(true),
                        loanStatus: loanStatus
                    }}
                    activeProps={activeProps}
                    pendingProps={pendingProps}
                    name={name}
                />

                <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'flex' }, backgroundColor: 'white', width: 2, borderRadius: 10 }} />
                {renderSteps(isMobile)}
            </Box>

            {/* PUSH MODAL */}
            <Modal open={pushModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {  // ----------------> FOR MOBILE
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" component="button" onClick={() => setPushModal(false)} />
                    <DialogTitle>Enter Amount</DialogTitle>
                    <DialogContent>Please enter amount to pay and confirm.</DialogContent>
                    <Typography level="body-sm" textAlign="center">
                        The popup window will appear in your phone with this {loginResult.mobile} mobile number, confirm with your password to complete
                    </Typography>
                    <form
                    // onSubmit={startPush}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            {/* <FormLabel></FormLabel> */}
                            <Button variant="plain" onClick={() => setWholeAmount(true)}>Pay whole remain amount</Button>
                            <Input
                                autoFocus
                                type="number"
                                required
                                value={inputAmount}
                                defaultValue={inputAmount}
                                onChange={(event) => setInputAmount(event.target.value)}
                            />
                        </FormControl>

                        <Button
                            startDecorator={loadPush && <CircularProgress />}
                            // type="submit"
                            onClick={startPush}
                            fullWidth>
                            {loadPush ? "LOADING..." : "CONFIRM"}
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>
        </Box>
    )
}


const mapStateToProps = ({ authClient, account, loan }) => {
    const {
        otpResult: loginResult,
    } = authClient
    const {
        requestPaymentMethodResult: paymentMethodResult,
        requestPaymentMethodStatus: paymentMethodStatus,
        requestPaymentMethodErrorMessage: paymentMethodErrorMessage,


        transactionHistoryRequestResult: transactionHistoryRequestResult,
        transactionHistoryRequestStatus: transactionHistoryRequestStatus,

        clientDetailsStatus: clientDetailsStatus,
        clientDetailsResult: clientDetailsResult,
        clientDetailsErrorMessage: clientDetailsErrorMessage,
    } = account
    const {
        loanHistoryRequestStatus: loanHistoryStatus,
        loanHistoryRequestErrorMessage: loanHistoryErrorMessage,
        loanHistoryRequestResult: loanHistoryResult,

        pendingLoanDetailStatus: pendingLoanDetailStatus,
        pendingLoanDetailResult: pendingLoanDetailResult,
        pendingLoanDetailErrorMessage: pendingLoanDetailErrorMessage,

        activeLoanDetailStatus: loanDetailRequestStatus,
        activeLoanDetailResult: loanDetailResult,
        activeLoanDetailErrorMessage: loanDetailErrorMessage,

        initiatePushPaymentStatus: initiatePushPaymentStatus,
        initiatePushPaymentResult: initiatePushPaymentResult,
        initiatePushPaymentErrorMessage: initiatePushPaymentErrorMessage
    } = loan

    return {
        loginResult,
        loanHistoryResult,
        loanHistoryStatus,
        loanHistoryErrorMessage,

        pendingLoanDetailStatus,
        pendingLoanDetailResult,
        pendingLoanDetailErrorMessage,

        clientDetailsStatus,
        clientDetailsResult,
        clientDetailsErrorMessage,

        loanDetailRequestStatus,
        loanDetailResult,
        loanDetailErrorMessage,
        paymentMethodResult,
        paymentMethodStatus,
        paymentMethodErrorMessage,
        initiatePushPaymentStatus,
        initiatePushPaymentResult,
        initiatePushPaymentErrorMessage,
        transactionHistoryRequestStatus,
        transactionHistoryRequestResult,
    }
}
export default connect(mapStateToProps, {})(HomeClient)