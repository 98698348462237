import React, { useEffect, useState } from "react";
import { Avatar, Box, Checkbox, Chip, ColorPaletteProp, Divider, Dropdown, IconButton, List, Link, ListDivider, ListItem, ListItemContent, ListItemDecorator, Menu, MenuButton, MenuItem, Table, Typography, Sheet, Input, Modal, ModalDialog, ModalClose, FormControl, FormLabel, iconButtonClasses, Button, Card, } from "@mui/joy";
import { toast } from 'react-toastify';
import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { STATUS } from "../../../constant";


import transData from "../../../assets/fakeData/transactionHistory.json"

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
    activeLoanDetailRequest, activeLoanDetailReset,
    paymentMethodReset,
    initiatePushPaymentReset,
    loanHistoryReset,

    transactionHistoryRequest,

    transactionHistoryReset,
    pendingLoanDetailRequest, pendingLoanDetailReset,
    initiatePushPaymentRequest, clientDetailsReset
} from "../../../store/actions"
import { formatDate, thousandSeparator } from "../../../utils";
import { BackHand, ForwardRounded, WarningRounded } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MobileViewTable = ({ transactions }) => {

    const transType = (type) => {
        if (type === 2) {
            return <ForwardRounded />
        } else if (type === 1) {
            return <BackHand />
        }
    }
    return (
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {transactions.map((listItem) => (
                <List
                    key={listItem.id}
                    size="sm"
                    sx={{
                        '--ListItem-paddingX': 0,
                    }}
                >
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                        }}
                    >
                        <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                            {/* <ListItemDecorator>
                                <ForwardRounded sx={{
                                    '& svg': {
                                        transform: listItem.trans_type === '2' ? 'rotate(0deg)' : 'rotate(180deg)'
                                    }
                                }} />
                            </ListItemDecorator> */}
                            <div>
                                <Typography fontWeight={600} gutterBottom>
                                   Amount: Tsh. {thousandSeparator(listItem.amount)}
                                </Typography>
                                <Typography level="body-xs" gutterBottom>
                                    Ref No: {listItem.trans_ref}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 0.5,
                                        mb: 1,
                                    }}
                                >
                                    <Typography level="body-xs">{formatDate(listItem.trans_date)}</Typography>
                                    <Typography level="body-xs">&bull;</Typography>
                                    <Typography level="body-xs">{listItem.channel}</Typography>
                                </Box>
                            </div>
                        </ListItemContent>
                        <Chip
                            variant="soft"
                            size="sm"
                            startDecorator={
                                {
                                    2: <ForwardRounded />,
                                    1: <ForwardRounded sx={{transform: 'rotate(180deg)'}} />,
                                }[listItem.trans_type]
                            }
                            color={
                                {
                                    2: 'success',
                                    1: 'neutral',
                                }[listItem.trans_type] as ColorPaletteProp
                            }
                        >
                            {listItem.trans_type === "2" ? "Receive" : "Send"}
                        </Chip>
                    </ListItem>
                    <ListDivider />
                </List>
            ))}
        </Box>
    )
}

const DesktopViewTable = ({ transactions }) => {
    return (
        <React.Fragment>
            <Sheet
                className="OrderTableContainer"
                variant="outlined"
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    // width: '100%',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ width: 140, padding: '12px 6px' }}>Date</th>
                            <th style={{ width: 140, padding: '12px 6px' }}>Amount</th>
                            <th style={{ width: 140, padding: '12px 6px' }}>Reference No</th>
                            {/* <th style={{ width: 240, padding: '12px 6px' }}>Reference</th> */}
                            <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((row) => (
                            <tr key={row.trans_id}>
                                <td>
                                    <Typography level="body-xs">{formatDate(row.trans_date)}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">Tsh. {thousandSeparator(row.amount)}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{row.trans_ref}</Typography>
                                </td>
                                {/* <td>
                                    <Typography level="body-xs">{row.loan_reference}</Typography>
                                </td> */}
                                <td>
                                    <Chip
                                        variant="outlined"
                                        size="sm"
                                        startDecorator={
                                            {
                                                2: <ForwardRounded />,
                                                1: <ForwardRounded sx={{transform: 'rotate(180deg)'}} />,
                                            }[row.trans_type]
                                        }
                                        color={
                                            {
                                                2: 'success',
                                                1: 'neutral',
                                            }[row.trans_type] as ColorPaletteProp
                                        }
                                    >
                                        {row.trans_type === "2" ? "Receive" : "Send"}
                                    </Chip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </React.Fragment>
    );
}

const ClientTransactionHistoryPage = ({
    loginResult,

    transactionHistoryRequestStatus,
    transactionHistoryRequestResult,
    transactionHistoryRequestErrorMessage
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isDesktop = useMediaQuery("(min-width:600px)");

    const [transactions, setTransactions] = useState([]);


    useEffect(() => {
        // loan history
        if (transactionHistoryRequestStatus === STATUS.SUCCESS) {
            // console.log("TRANSACTIONS: ", transactionHistoryRequestResult.history)
            setTransactions(transactionHistoryRequestResult.history)
        }
        else if (transactionHistoryRequestStatus === STATUS.ERROR) {
            toast.error(transactionHistoryRequestErrorMessage)
            dispatch(transactionHistoryReset())
        }
    }, [transactionHistoryRequestStatus])

    const [currentPage, setCurrentPage] = useState(0);
    const ITEMS_PER_PAGE = 20;

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(transactions.length / ITEMS_PER_PAGE) - 1));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = transactions.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

    const checkLoading = () => {
        if (transactionHistoryRequestStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={'Transaction History'} />
            <LoadingView loading={checkLoading()} />

            {transactions.length > 0 ? <>

                {/* search */}
                <Sheet
                    className="SearchAndFilters"
                    sx={{
                        display: 'flex',
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        placeholder="Search"
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                </Sheet>
                <MobileViewTable transactions={paginatedData} />
                <DesktopViewTable transactions={paginatedData} />

                {/* Pagination */}
                {transactions.length > ITEMS_PER_PAGE
                    &&
                    <Box
                        className="Pagination-laptopUp"
                        sx={{
                            pt: 2,
                            gap: 1,
                            [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
                            display: 'flex'
                        }}
                    >
                        <Button
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            startDecorator={<KeyboardArrowLeftIcon />}
                            onClick={handlePreviousPage}
                            disabled={currentPage === 0}
                        >
                            {isDesktop ? 'Previous' : ""}
                        </Button>


                        <Box sx={{ flex: 1 }} />
                        {/* for desktop to display page number */}
                        {Array.from({ length: Math.ceil(transactions.length / ITEMS_PER_PAGE) }).map((_, page) => (
                            <IconButton
                                key={page}
                                size="sm"
                                variant={'outlined'}
                                color="neutral"
                                onClick={() => handlePageChange(page)}
                                disabled={currentPage === page}
                                sx={{ display: { xs: 'none', md: 'flex' } }}
                            >
                                {page + 1}
                            </IconButton>
                        ))}

                        {/* for mobile to display page number */}
                        <Typography level="body-sm" mx="auto" textAlign={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
                            Page {currentPage + 1} of {Math.ceil(transactions.length / ITEMS_PER_PAGE)}
                        </Typography>
                        <Box sx={{ flex: 1 }} />



                        <Button
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            endDecorator={<KeyboardArrowRightIcon />}
                            onClick={handleNextPage}
                            disabled={currentPage === Math.ceil(transactions.length / ITEMS_PER_PAGE) - 1}
                        >
                            {isDesktop ? 'Next' : ""}
                        </Button>
                    </Box>}

            </> :
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '20%'
                }}>
                    <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                    <Typography level="title-md">No Transaction History Found</Typography>
                    <Typography level="body-sm" textAlign={'center'}>This can be either you don't have transactions history or network issue.</Typography>
                </Card>
            }
        </Box>
    )
}

const mapStateToProps = ({ authClient, account }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        transactionHistoryRequestStatus: transactionHistoryRequestStatus,
        transactionHistoryRequestErrorMessage: transactionHistoryRequestErrorMessage,
        transactionHistoryRequestResult: transactionHistoryRequestResult,
    } = account

    return {
        loginResult,

        transactionHistoryRequestStatus,
        transactionHistoryRequestResult,
        transactionHistoryRequestErrorMessage
    }
}

export default connect(mapStateToProps, {})(ClientTransactionHistoryPage)