import { OTP_URL, REGISTRATION_URL, UPDATE_OTP_URL } from "../../constant"
import { guestRequest} from "../calls"

function doUserRegistrationRequest(name, mobile, nida, pin, repin, region, district, invite_code) {
    const data = {
        name,
        mobile,
        nida,
        pin,
        repin,
        region,
        district,
        invite_code
    }

    return guestRequest(REGISTRATION_URL, data);
}

function doOtpRegistrationRequest(mobile, otp) {
    const data = {
        mobile,
        otp
    }

    return guestRequest(OTP_URL, data);
}

function doResendUserOtpRequest(mobile) {
    const data = {
        mobile
    };
    return guestRequest(UPDATE_OTP_URL, data);
}

export {
    doUserRegistrationRequest,
    doOtpRegistrationRequest,
    doResendUserOtpRequest
}