import { call, put, takeLatest } from "@redux-saga/core/effects";
import { ACTION_ACCOUNT_SETUP, CLIENT } from "../../constant";
import { doOtpRegistrationRequest, doResendUserOtpRequest, doUserRegistrationRequest } from "../../service/registration";
import errorMessage from "../../utils/client_error.json";


function* userRegistrationTask(action) {
    try {
        yield put({ type: CLIENT.USER_REGISTRATION_LOADING });

        const { payload } = action;

        const res = yield call(doUserRegistrationRequest, payload.name, payload.mobile, payload.nida, payload.pin, payload.repin, payload.region, payload.district, payload.inviteCode);

        
        if (res.status == 200) {
            yield put({
                type: CLIENT.USER_REGISTRATION_SUCCESS,
                payload: res.data
            });

        } else {
           // console.log(res.data.code);
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.USER_REGISTRATION_FAILURE,
                payload: res.message
            });
        }

    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.USER_REGISTRATION_FAILURE,
            payload: errMsg
        })
    }
}

function* otpRegistrationTask(action) {
    try {
        yield put({ type: CLIENT.OTP_REGISTRATION_LOADING });

        const { payload } = action;

        const res = yield call(doOtpRegistrationRequest, payload.mobile, payload.otp);

        //console.log(res);
        if (res.status == 200) {
            yield put({
                type: CLIENT.OTP_REGISTRATION_SUCCESS,
                payload: res
            })
        } else {

            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.OTP_REGISTRATION_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.OTP_REGISTRATION_FAILURE,
            payload: errMsg
        })
    }
}

function* resendOtpTask(action) {
    try {
        yield put({ type: CLIENT.RESEND_USER_OTP_LOADING })

        const { payload } = action;

        const res = yield call(doResendUserOtpRequest, payload.mobile);

        if (res.status == 200) {
            yield put({ 
                type: CLIENT.RESEND_USER_OTP_SUCCESS,
                payload:res
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.RESEND_USER_OTP_FAILURE,
                payload: res.message
            })
           
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.RESEND_USER_OTP_FAILURE,
            payload: errMsg
        })
    }
}

function* registrationSaga() {
    yield takeLatest(CLIENT.USER_REGISTRATION_REQUEST, userRegistrationTask);
    yield takeLatest(CLIENT.OTP_REGISTRATION_REQUEST, otpRegistrationTask);
    yield takeLatest(CLIENT.RESEND_USER_OTP_REQUEST, resendOtpTask);
}

export default registrationSaga;