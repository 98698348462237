import { ACTION_ACCOUNT_REMOVE, ACTION_ACCOUNT_SETUP, CLIENT, STATUS } from "../../constant";

const INITIAL_STATE = {
    // account information
    accountState: STATUS.ACCOUNT.NOT_SET,

    //support - help states
    requestSupportListResult: [],
    requestSupportListStatus: STATUS.DEFAULT,
    requestSupportListErrorMessage: '',
  
    
    //update profile photo states
    profilePhotoUpdateStatus: STATUS.DEFAULT,
    profilePhoto: '',

    // upload signature
    signatureStatus: STATUS.DEFAULT,
    signatureResult: null,
    sugnatureErrorMessage: '',

    //Fetch payment methods
    requestPaymentMethodResult: [],
    requestPaymentMethodStatus: STATUS.DEFAULT,
    requestPaymentMethodErrorMessage: '',

    //Fetch Transactions from  the database
    transactionHistoryRequestStatus: STATUS.DEFAULT,
    transactionHistoryRequestErrorMessage: "",
    transactionHistoryRequestResult: [],

    //Fetch customer chat
    clientChatListRequestStatus: STATUS.DEFAULT,
    clientChatListRequestResult: [],
    clientChatListRequestErrorMessage: "",

    //Send client Chat
    sendClientChatStatus: STATUS.DEFAULT,
    sendClientChatResult: [],
    sendClientChatErrorMessage: "",

    //Fetch client Notification
    clientNotificationStatus: STATUS.DEFAULT,
    clientNotificationResult: [],
    clientNotificationErrorMessage: "",

    //Fetch client Details
    clientDetailsStatus: STATUS.DEFAULT,
    clientDetailsResult: [],
    clientDetailsErrorMessage: ""
}

export default (state = INITIAL_STATE, {type, payload}) => {
    switch(type) {
        case ACTION_ACCOUNT_SETUP:
            return {
                ...state,
                accountState: STATUS.ACCOUNT.SETUP
            }
        case ACTION_ACCOUNT_REMOVE: {
            return {
                ...state,
                accountState: STATUS.ACCOUNT.NOT_SET
            }
        }
        case CLIENT.SUPPORT_LIST_LOADING:
            return {
                ...state,
                requestSupportListResult: [],
                requestSupportListStatus: STATUS.LOADING,
                requestSupportListErrorMessage: ''
            }
        case CLIENT.SUPPORT_LIST_SUCCESS:
            return {
                ...state,
                requestSupportListResult: payload,
                requestSupportListStatus: STATUS.SUCCESS,
                requestSupportListErrorMessage: ''
            }
        case CLIENT.SUPPORT_LIST_FAILURE: 
            return {
                ...state,
                requestSupportListResult: [],
                requestSupportListStatus: STATUS.ERROR,
                requestSupportListErrorMessage: payload
            }
        case CLIENT.SUPPORT_LIST_RESET: 
            return {
                ...state,
                requestSupportListResult: [],
                requestSupportListStatus: STATUS.DEFAULT,
                requestSupportListErrorMessage: ''
            }
        case CLIENT.UPDATE_PROFILE_PHOTO_LOADING: 
            return {
                ...state,
                profilePhotoUpdateStatus: STATUS.LOADING,
                profilePhoto: ''
            }
        case CLIENT.UPDATE_PROFILE_PHOTO_SUCCESS: 
            return {
                ...state,
                profilePhotoUpdateStatus: STATUS.SUCCESS,
                profilePhoto: payload.profile_photo
            }
        case CLIENT.UPDATE_PROFILE_PHOTO_FAILURE: 
            return {
                ...state,
                profilePhotoUpdateStatus: STATUS.ERROR,
                profilePhoto: ''
            }
        case CLIENT.UPDATE_PROFILE_PHOTO_RESET: 
            return {
                ...state,
                profilePhotoUpdateStatus: STATUS.DEFAULT,
                profilePhoto: ''
            }

             // UPLOAD SIGNATURE
        case CLIENT.UPLOAD_SIGNATURE_LOADING:
            return {
                ...state,
                signatureStatus: STATUS.LOADING,
            }
        case CLIENT.UPLOAD_SIGNATURE_SUCCESS:
            return {
                ...state,
                signatureStatus: STATUS.SUCCESS,
                signatureResult: payload,
                signatureErrorMessage: ''
            }
        case CLIENT.UPLOAD_SIGNATURE_FAILURE:
            return {
                ...state,
                signatureStatus: STATUS.ERROR,
                signatureResult: [],
                signatureErrorMessage: payload
            }
        case CLIENT.UPLOAD_SIGNATURE_RESET:
            return {
                ...state,
                signatureStatus: STATUS.DEFAULT,
                signatureResult: [],
                signatureErrorMessage: ''
            }

        case CLIENT.PAYMENT_METHOD_LIST_LOADING: 
            return {
                ...state,
                requestPaymentMethodStatus: STATUS.LOADING,
            }
        case CLIENT.PAYMENT_METHOD_LIST_SUCCESS: 
            return {
                ...state,
                requestPaymentMethodStatus: STATUS.SUCCESS,
                requestPaymentMethodResult: payload,
                requestPaymentMethodErrorMessage: ''
            }
        case CLIENT.PAYMENT_METHOD_LIST_FAILURE: 
            return {
                ...state,
                requestPaymentMethodStatus: STATUS.ERROR,
                requestPaymentMethodResult: [],
                requestPaymentMethodErrorMessage: payload
            }
        case CLIENT.PAYMENT_METHOD_LIST_RESET: 
            return {
                ...state,
                requestPaymentMethodStatus: STATUS.DEFAULT,
                requestPaymentMethodResult: [],
                requestPaymentMethodErrorMessage: ''
            }
        case CLIENT.TRANSACTION_HISTORIES_LOADING: 
            return {
                ...state,
                transactionHistoryRequestStatus: STATUS.LOADING,
                transactionHistoryRequestErrorMessage: "",
                transactionHistoryRequestResult: []
            }   
        case CLIENT.TRANSACTION_HISTORIES_SUCCESS: 
            return {
                ...state,
                transactionHistoryRequestStatus: STATUS.SUCCESS,
                transactionHistoryRequestErrorMessage: "",
                transactionHistoryRequestResult: payload
            }
        case CLIENT.TRANSACTION_HISTORIES_FAILURE: 
            return {
                ...state,
                transactionHistoryRequestStatus: STATUS.ERROR,
                transactionHistoryRequestErrorMessage: payload,
                transactionHistoryRequestResult: []
            }
        case CLIENT.TRANSACTION_HISTORIES_RESET: 
            return {
                ...state,
                transactionHistoryRequestStatus: STATUS.DEFAULT,
                transactionHistoryRequestErrorMessage: "",
                transactionHistoryRequestResult: []
            }
        case CLIENT.CLIENT_CHAT_LIST_LOADING: 
            return {
                ...state,
                clientChatListRequestStatus: STATUS.LOADING,
                clientChatListRequestResult: [],
                clientChatListRequestErrorMessage: "",
            }
        case CLIENT.CLIENT_CHAT_LIST_SUCCESS: 
            return {
                ...state,
                clientChatListRequestStatus: STATUS.SUCCESS,
                clientChatListRequestResult: payload,
                clientChatListRequestErrorMessage: "",
            }
        case CLIENT.CLIENT_CHAT_LIST_FAILURE: 
            return {
                ...state,
                clientChatListRequestStatus: STATUS.ERROR,
                clientChatListRequestResult: [],
                clientChatListRequestErrorMessage: payload,
            }
        case CLIENT.CLIENT_CHAT_LIST_RESET: 
            return {
                ...state,
                clientChatListRequestStatus: STATUS.DEFAULT,
                //clientChatListRequestResult: [],
                clientChatListRequestErrorMessage: "",
            }
        case CLIENT.SEND_CLIENT_CHAT_LOADING: 
            return {
                ...state,
                sendClientChatStatus: STATUS.LOADING,
                sendClientChatResult: [],
                sendClientChatErrorMessage: ""
            }
        case CLIENT.SEND_CLIENT_CHAT_SUCCESS: 
            return {
                ...state,
                sendClientChatStatus: STATUS.SUCCESS,
                sendClientChatErrorMessage: ""
            }
        case CLIENT.SEND_CLIENT_CHAT_FAILURE:
            return {
                ...state,
                sendClientChatStatus: STATUS.ERROR,
                sendClientChatErrorMessage: payload
            }
        case CLIENT.SEND_CLIENT_CHAT_RESET:
            return {
                ...state,
                sendClientChatStatus: STATUS.DEFAULT,
                sendClientChatErrorMessage: ""
            }
        case CLIENT.CLIENT_NOTIFICATION_SUCCESS: 
            return {
                ...state,
                clientNotificationStatus: STATUS.SUCCESS,
                clientNotificationResult: payload,
                clientNotificationErrorMessage: ""
            }
        
        case CLIENT.CLIENT_NOTIFICATION_FAILURE: 
            return {
                ...state,
                clientNotificationStatus: STATUS.ERROR,
                clientNotificationResult: [],
                clientNotificationErrorMessage: payload
            }
        
        case CLIENT.CLIENT_NOTIFICATION_LOADING: 
            return {
                ...state,
                clientNotificationStatus: STATUS.LOADING,
                clientNotificationResult: [],
                clientNotificationErrorMessage: ""
            }
        
        case CLIENT.CLIENT_NOTIFICATION_RESET: 
            return {
                ...state,
                clientNotificationStatus: STATUS.DEFAULT,
                clientNotificationResult: [],
                clientNotificationErrorMessage: ""
            }

            // CLIENT DETAILS
            case CLIENT.CLIENT_DETAILS_SUCCESS: 
            return {
                ...state,
                clientDetailsStatus: STATUS.SUCCESS,
                clientDetailsResult: payload,
                clientDetailsErrorMessage: ""
            }
        
        case CLIENT.CLIENT_DETAILS_FAILURE: 
            return {
                ...state,
                clientDetailsStatus: STATUS.ERROR,
                clientDetailsResult: [],
                clientDetailsErrorMessage: payload
            }
        
        case CLIENT.CLIENT_DETAILS_LOADING: 
            return {
                ...state,
                clientDetailsStatus: STATUS.LOADING,
                clientDetailsResult: [],
                clientDetailsErrorMessage: ""
            }
        
        case CLIENT.CLIENT_DETAILS_RESET: 
            return {
                ...state,
                clientDetailsStatus: STATUS.DEFAULT,
                clientDetailsResult: [],
                clientDetailsErrorMessage: ""
            }
        default:
            return state;
    }
}