import { call, put, takeLatest } from 'redux-saga/effects';
import { CLIENT } from '../../constant';
import { doFetchClientChatHistory, doFetchClientDetails, doFetchClientNotification, doFetchSupportContent, doSendClientChat, doTransactionHistoryRequest, doUploadProfilePhoto } from '../../service/account';
import errorMessage from "../../utils/client_error.json";


function* fetchSupportContentTask(action) {
    try {
        yield put({type: CLIENT.SUPPORT_LIST_LOADING });

        const { payload } = action;

        const res = yield call(doFetchSupportContent, payload.token, payload.language, payload.category);


        if (res.status == 200) {
            yield put({
                type: CLIENT.SUPPORT_LIST_SUCCESS,
                payload: res.data
            });
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.SUPPORT_LIST_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.SUPPORT_LIST_FAILURE,
            payload: errMsg
        })
    }
}

function* fetchPaymentMethodTask(action) {
    try {
        yield put({ type: CLIENT.PAYMENT_METHOD_LIST_LOADING });

        const { payload } = action;

        const res = yield call(doFetchSupportContent, payload.token, payload.language, payload.category);

        if (res.status == 200) {
            yield put({
                type: CLIENT.PAYMENT_METHOD_LIST_SUCCESS,
                payload: res.data
            });
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.PAYMENT_METHOD_LIST_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.PAYMENT_METHOD_LIST_FAILURE,
            payload: errMsg
        })
    }
}

function* uploadProfilePhotoTask(action) {
    try {
        yield put({ type: CLIENT.UPDATE_PROFILE_PHOTO_LOADING });

        const { payload } = action;

        const res = yield call(doUploadProfilePhoto, payload.token, payload.file);

        if (res.status == 200) {
            yield put({
                type: CLIENT.UPDATE_PROFILE_PHOTO_SUCCESS,
                payload: res.data
            });
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.UPDATE_PROFILE_PHOTO_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        console.log(e);
        const errMsg = e.data ? errorMessage[e.code]  :   errorMessage[4000];
        yield put({
            type: CLIENT.UPDATE_PROFILE_PHOTO_FAILURE,
            payload: errMsg
        })
    }
}

function* uploadSignatureTask(action) {
    try {
        yield put({ type: CLIENT.UPLOAD_SIGNATURE_LOADING });

        const { payload } = action;

        const res = yield call(doUploadProfilePhoto, payload.token, payload.file);

        if (res.status == 200) {
            yield put({
                type: CLIENT.UPLOAD_SIGNATURE_SUCCESS,
                payload: res.data
            });
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.UPLOAD_SIGNATURE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        console.log(e);
        const errMsg = e.data ? errorMessage[e.code]  :   errorMessage[4000];
        yield put({
            type: CLIENT.UPLOAD_SIGNATURE_FAILURE,
            payload: errMsg
        })
    }
}

function* transactionHistoryRequestTask(action) {
    try {
        yield put({ type: CLIENT.TRANSACTION_HISTORIES_LOADING });

        const { payload } = action; 

       const res = yield call(doTransactionHistoryRequest, payload.token);

       if (res.status == 200) {
            yield put({
                type: CLIENT.TRANSACTION_HISTORIES_SUCCESS,
                payload: res.data,
            });
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.TRANSACTION_HISTORIES_FAILURE,
                payload: res.message 
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.TRANSACTION_HISTORIES_FAILURE,
            payload: errMsg 
        })
    }
}

function* sendClientChatRequestTask(action) {
    try {
        yield put({ type: CLIENT.SEND_CLIENT_CHAT_LOADING });

        const { payload } = action;

        const res = yield call(doSendClientChat, payload.token, payload.message);

        if (res.status == 200) {
            yield put({
                type: CLIENT.SEND_CLIENT_CHAT_SUCCESS,
            });

            yield put({
                type: CLIENT.CLIENT_CHAT_LIST_SUCCESS,
                payload: res.data.history
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.SEND_CLIENT_CHAT_FAILURE,
                payload: res.message 
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.SEND_CLIENT_CHAT_FAILURE,
            payload: errMsg 
        })
    }
}

function* fetchClientChatHistoryTask(action) {
    try {
        yield put({ type: CLIENT.CLIENT_CHAT_LIST_LOADING });

        const { payload } = action;

        const res = yield call(doFetchClientChatHistory, payload.token);

        if (res.status == 200) {
            yield put({
                type: CLIENT.CLIENT_CHAT_LIST_SUCCESS,
                payload: res.data.history
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.CLIENT_CHAT_LIST_FAILURE,
                payload: res.message 
            })
        }         
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.CLIENT_CHAT_LIST_FAILURE,
            payload: errMsg 
        })
    }
}

function* fetchClientNotificationTask(action) {
    try {
        yield put({ type: CLIENT.CLIENT_NOTIFICATION_LOADING });

        const { payload } = action;

        const res = yield call(doFetchClientNotification, payload.token);

        if (res.status == 200) {
            yield put({
                type: CLIENT.CLIENT_NOTIFICATION_SUCCESS,
                payload: res.data.notification
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.CLIENT_NOTIFICATION_FAILURE,
                payload: res.message 
            })
        }         
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.CLIENT_NOTIFICATION_FAILURE,
            payload: errMsg 
        })
    }
}

function* fetchClientDetaialsTask(action) {
    try {
        yield put({ type: CLIENT.CLIENT_DETAILS_LOADING });

        const { payload } = action;

        const res = yield call(doFetchClientDetails, payload.token);

        if (res.status == 200) {
            yield put({
                type: CLIENT.CLIENT_DETAILS_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.code] : res.message = errorMessage[1000];
            yield put({
                type: CLIENT.CLIENT_DETAILS_FAILURE,
                payload: res.message 
            })
        }         
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.code]  :  errorMessage[4000];
        yield put({
            type: CLIENT.CLIENT_DETAILS_FAILURE,
            payload: errMsg 
        })
    }
}

function* accountClientSaga() {
   yield takeLatest(CLIENT.SUPPORT_LIST_REQUEST, fetchSupportContentTask);
   yield takeLatest(CLIENT.UPDATE_PROFILE_PHOTO_REQUEST, uploadProfilePhotoTask);
   yield takeLatest(CLIENT.UPLOAD_SIGNATURE_REQUEST, uploadSignatureTask)
   yield takeLatest(CLIENT.PAYMENT_METHOD_LIST_REQUEST, fetchPaymentMethodTask);
   yield takeLatest(CLIENT.TRANSACTION_HISTORIES_REQUEST, transactionHistoryRequestTask);
   yield takeLatest(CLIENT.SEND_CLIENT_CHAT_REQUEST, sendClientChatRequestTask);
   yield takeLatest(CLIENT.CLIENT_CHAT_LIST_REQUEST, fetchClientChatHistoryTask);
   yield takeLatest(CLIENT.CLIENT_NOTIFICATION_REQUEST, fetchClientNotificationTask);
   yield takeLatest(CLIENT.CLIENT_DETAILS_REQUEST, fetchClientDetaialsTask);
}

export default accountClientSaga;