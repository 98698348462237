import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Checkbox, Divider, Link, Typography } from "@mui/joy";
import { toast, ToastContainer } from 'react-toastify';
import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { thousandSeparator } from "../../../utils";
import { loanApprovalRequest, loanApprovalReset, termsAndConditionRequest, resetTermsAndConditionRequest, clientDetailsRequest} from '../../../store/actions'
import { STATUS } from "../../../constant";
import { NAVIGATE_TO_HOMECLIENTPAGE, NAVIGATE_TO_TERMSANDCONDITIONPAGE } from "../../../route/types";

const ClientLoanCompletePage = ({
    loginResult,
    requestStatus,
    requestErrorMessage,
    requestResult,
    termsResult,
    termsStatus,
    termsErrorMessage
}) => {

    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()

    const { ambassador_tile, ambassador_name, loanAmount, totalAmount, selectedPlan, duration } = state
    const [check, setCheck] = useState(true)

    useEffect(() => {
        if (requestStatus === STATUS.SUCCESS) {
            toast("Loan request submitted successful")
            setTimeout(() => {
                navigate(NAVIGATE_TO_HOMECLIENTPAGE)
            }, 1000)
            dispatch(clientDetailsRequest(loginResult.token))
            dispatch(loanApprovalReset())
        } else if (requestStatus === STATUS.ERROR) {
            toast(requestErrorMessage)
            dispatch(loanApprovalReset())
        }

        if (termsStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_TERMSANDCONDITIONPAGE, {
                state: {
                    content: termsResult.content
                }
            })
            dispatch(resetTermsAndConditionRequest());
        }
        else if (termsStatus === STATUS.ERROR) {
            // dropDownAlertRef.alertWithType('error', translate("alert.error"), termsErrorMessage);
            toast.error(termsErrorMessage)
            dispatch(resetTermsAndConditionRequest());
        }
    }, [requestStatus, termsStatus])

    const loan_Details = [
        { title: "Total Loan Request", content: "Tsh. " + thousandSeparator(loanAmount) },
        { title: "Total Payment Amount", content: "Tsh. " + thousandSeparator(totalAmount) },
        { title: "Loan Duration", content: duration },
        { title: "Ambassador name: ", content: ambassador_name },
        { title: "Ambassador tile: ", content: ambassador_tile }
    ]

    const verifyLoan = () => {
        if (check) {
            dispatch(loanApprovalRequest(loginResult.token, ambassador_tile, loanAmount, duration, selectedPlan))
        } else {
            toast("Please click checkbox to accept terms and conditions")
        }
        
    }

    const viewTerms = () => {
        //navigation.navigate(NAVIGATION_LOAN_TERMS_SCREEN);
        let language = 'sw';
        dispatch(termsAndConditionRequest(language));
    }

    const checkLoading = () => {
        if (requestStatus === STATUS.LOADING || termsStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <Box>
            <PageTitle title={'Confirm Loan'} />

            <LoadingView loading={checkLoading()} />

            <Card variant="outlined" sx={{ backgroundColor: 'background.level1', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Typography level="title-lg" textAlign="left">Loan Details</Typography>
                    {loan_Details.map((details) => (
                        <>
                            <Box
                                key={0}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    pt: 1
                                }}>
                                <Typography level="body-md">{details.title}</Typography>
                                <Typography level="body-md" textAlign='right'><b>{details.content}</b></Typography>

                            </Box>
                            <Divider />
                        </>

                    ))}
                    {/* checkboc for agrement */}
                    <Checkbox
                        checked={check}
                        defaultChecked
                        onChange={(event) => setCheck(event.target.checked)}
                        size="md"
                        label="I agree Sava Loan to review and verifying the information I provided before approving this loan."
                    // name="checkBox"
                    />
                    <Link onClick={viewTerms} sx={{color: 'green'}}>Terms and Conditions</Link>
                </CardContent>
                <CardActions sx={{ width: "100%" }}>
                    <Button onClick={verifyLoan}>CONFIRM</Button>
                </CardActions>
            </Card>
        </Box>
    )
}


const mapStateToProps = ({ loan, authClient, guest }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        loanRequestStatus: requestStatus,
        loanRequestErrorMessage: requestErrorMessage,
        loanRequestResult: requestResult
    } = loan
    const {
        termsAndConditionResult: termsResult,
        termsAndConditionStatus: termsStatus,
        termsAndConditionErrorMessage: termsErrorMessage
    } = guest
    return {
        loginResult,
        requestStatus,
        requestErrorMessage,
        requestResult,
        termsResult,
        termsStatus,
        termsErrorMessage
    }
}

export default connect(mapStateToProps, {})(ClientLoanCompletePage)