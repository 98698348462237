import React, { useEffect, useState } from "react";
import { Avatar, Box, Checkbox, Chip, ColorPaletteProp, Divider, Dropdown, IconButton, List, Link, ListDivider, ListItem, ListItemContent, ListItemDecorator, Menu, MenuButton, MenuItem, Table, Typography, Sheet, Input, Modal, ModalDialog, ModalClose, FormControl, FormLabel, iconButtonClasses, Button, Card, } from "@mui/joy";
import { toast, ToastContainer } from 'react-toastify';
import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { STATUS } from "../../../constant";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import loanHistoryDT from "../../../assets/fakeData/clientHistory.json"

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  activeLoanDetailRequest, activeLoanDetailReset,
  paymentMethodReset,
  initiatePushPaymentReset,
  loanHistoryReset,
  pendingLoanDetailRequest, pendingLoanDetailReset,
  initiatePushPaymentRequest, clientDetailsReset
} from "../../../store/actions"
import { formatDate, thousandSeparator } from "../../../utils";
import { WarningRounded } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

const MobileViewTable = ({ loanHistory }) => {
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {loanHistory.map((listItem) => (
        <List
          key={listItem.id}
          size="sm"
          sx={{
            '--ListItem-paddingX': 0,
          }}
        >
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
              {/* <ListItemDecorator>
                                <Avatar size="sm">{listItem.customer.initial}</Avatar>
                            </ListItemDecorator> */}
              <div>
                <Typography fontWeight={600} gutterBottom>
                  Tsh. {thousandSeparator(listItem.principle_amount)}
                </Typography>
                <Typography level="body-xs" gutterBottom>
                  {listItem.duration} Months
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 0.5,
                    mb: 1,
                  }}
                >
                  <Typography level="body-xs">{formatDate(listItem.approved_date)}</Typography>
                  <Typography level="body-xs">&bull;</Typography>
                  <Typography level="body-xs">Ref: {listItem.loan_reference}</Typography>
                </Box>
              </div>
            </ListItemContent>
            <Chip
              variant="soft"
              size="sm"
              startDecorator={
                {
                  Paid: <CheckRoundedIcon />,
                  Refunded: <AutorenewRoundedIcon />,
                  Cancelled: <BlockIcon />,
                }['Paid']
              }
              color={
                {
                  Paid: 'success',
                  Refunded: 'neutral',
                  Cancelled: 'danger',
                }['Paid'] as ColorPaletteProp
              }
            >
              {'Paid'}
            </Chip>
          </ListItem>
          <ListDivider />
        </List>
      ))}
    </Box>
  )
}

const DesktopViewTable = ({ loanHistory }) => {
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', md: 'flex' },
          // width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 140, padding: '12px 6px' }}>Date</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Loan Amount</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Duration</th>
              <th style={{ width: 240, padding: '12px 6px' }}>Reference</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {loanHistory.map((row) => (
              <tr key={row.approved_data}>
                <td>
                  <Typography level="body-xs">{formatDate(row.approved_date)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">Tsh. {thousandSeparator(row.principle_amount)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.duration} Months</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.loan_reference}</Typography>
                </td>
                <td>
                  <Chip
                    variant="outlined"
                    size="sm"
                    startDecorator={
                      {
                        Paid: <CheckRoundedIcon />,
                        Refunded: <AutorenewRoundedIcon />,
                        Cancelled: <BlockIcon />,
                      }['Paid']
                    }
                    color={
                      {
                        Paid: 'success',
                        Refunded: 'neutral',
                        Cancelled: 'danger',
                      }['Paid'] as ColorPaletteProp
                    }
                  >
                    {'Paid'}
                  </Chip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}

const ClientLoanHistoryPage = ({
  loginResult,

  loanHistoryStatus,
  loanHistoryResult,
  loanHistoryErrorMessage,

  clientDetailsStatus,
  clientDetailsResult,
  clientDetailsErrorMessage
}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery("(min-width:600px)");

  const [loanStatus, setLoanStatus] = useState('')
  const [loanHistory, setLoanHistory] = useState([])

  useEffect(() => {
    // client details
    if (clientDetailsStatus === STATUS.SUCCESS) {
      setLoanStatus(clientDetailsResult.loan_status)
      // if (clientDetailsResult.loan_status === "Active") {
      //     dispatch(activeLoanDetailRequest(loginResult.token));
      // } else if (clientDetailsResult.loan_status === "Pending") {
      //     dispatch(pendingLoanDetailRequest(loginResult.token));
      // }
    }
    else if (clientDetailsStatus === STATUS.ERROR) {
      // dispatch(clientDetailsReset())
      toast.error(clientDetailsErrorMessage)
    }
  }, [clientDetailsStatus])

  useEffect(() => {
    // loan history
    if (loanHistoryStatus === STATUS.SUCCESS) {
      // console.log("HISTORY: ", loanHistoryResult)
      setLoanHistory(loanHistoryResult.history)
      // dispatch(loanHistoryReset());
    }
    else if (loanHistoryStatus === STATUS.ERROR) {
      // toast.error(loanHistoryErrorMessage)
      dispatch(loanHistoryReset())
    }
  }, [loanHistoryStatus])


  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 20;

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(loanHistory.length / ITEMS_PER_PAGE) - 1));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = loanHistory.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

  const checkLoading = () => {
    if (loanHistoryStatus === STATUS.LOADING){
      return true
    } else {
      return false
    }
  }

  return (
    <Box>
      <PageTitle title='Loan History' />

      <LoadingView loading={checkLoading()}/>

      {loanHistory.length > 0 ? <>

      {/* search */}
        <Sheet
          className="SearchAndFilters"
          sx={{
            display: 'flex',
            my: 1,
            gap: 1,
          }}
        >
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            sx={{ flexGrow: 1 }}
          />
        </Sheet>
        <MobileViewTable loanHistory={paginatedData} />
        <DesktopViewTable loanHistory={paginatedData} />

        {/* Pagination */}
        {loanHistory.length > ITEMS_PER_PAGE
          &&
          <Box
            className="Pagination-laptopUp"
            sx={{
              pt: 2,
              gap: 1,
              [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
              display: 'flex'
            }}
          >
            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              startDecorator={<KeyboardArrowLeftIcon />}
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              {isDesktop ? 'Previous' : ""}
            </Button>


            <Box sx={{ flex: 1 }} />
            {/* for desktop to display page number */}
            {Array.from({ length: Math.ceil(loanHistory.length / ITEMS_PER_PAGE) }).map((_, page) => (
              <IconButton
                key={page}
                size="sm"
                variant={'outlined'}
                color="neutral"
                onClick={() => handlePageChange(page)}
                disabled={currentPage === page}
                sx={{ display: { xs: 'none', md: 'flex' } }}
              >
                {page + 1}
              </IconButton>
            ))}

            {/* for mobile to display page number */}
            <Typography level="body-sm" mx="auto" textAlign={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
              Page {currentPage + 1} of {Math.ceil(loanHistory.length / ITEMS_PER_PAGE)}
            </Typography>
            <Box sx={{ flex: 1 }} />



            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              endDecorator={<KeyboardArrowRightIcon />}
              onClick={handleNextPage}
              disabled={currentPage === Math.ceil(loanHistory.length / ITEMS_PER_PAGE) - 1}
            >
              {isDesktop ? 'Next' : ""}
            </Button>
          </Box>}

      </> :
        <Card sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          top: '20%'
        }}>
          <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
          <Typography level="title-md">No Loan History Found</Typography>
          <Typography level="body-sm" textAlign={'center'}>This can be either you don't have completed loans or network issue.</Typography>
        </Card>
      }
    </Box>
  )
}

const mapStateToProps = ({ authClient, loan, account }) => {
  const {
    otpResult: loginResult
  } = authClient
  const {
    loanHistoryRequestStatus: loanHistoryStatus,
    loanHistoryRequestErrorMessage: loanHistoryErrorMessage,
    loanHistoryRequestResult: loanHistoryResult,


  } = loan
  const {
    clientDetailsStatus: clientDetailsStatus,
    clientDetailsResult: clientDetailsResult,
    clientDetailsErrorMessage: clientDetailsErrorMessage
  } = account

  return {
    loginResult,

    loanHistoryStatus,
    loanHistoryResult,
    loanHistoryErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,
    clientDetailsErrorMessage
  }
}

export default connect(mapStateToProps, {})(ClientLoanHistoryPage)